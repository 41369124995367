<template>
  <draggable
    v-if="blocksData"
    v-model="blocksData"
    class="draggablelist"
    ghost-class="ghost"
    :disabled="!enabled"
    @end="updateBlocks"
  >
    <Block
      v-for="block in blocksData"
      :key="block.key"
      :block="block"
      :prospect="prospect"
      :project="project"
      :sortable="true"
      @dragenabled="toggleDrag"
    ></Block>
  </draggable>
</template>

<script>
  import draggable from 'vuedraggable'
  import Block from './Block'

  const fb = require('../../../../../../firebaseConfig')

  export default {
    name: 'SortableBlocks',
    components: {
      draggable,
      Block
    },
    props: {
      blocks: {
        type: Array,
        required: true
      },
      prospect: {
        type: Object,
        required: true
      },
      project: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        id: '',
        blocksData: this.blocks,
        openBlocks: [],
        drag: false,
        enabled: true,
        openedBlocks: {}
      }
    },
    watch: {
      // eslint-disable-next-line object-shorthand
      blocks: function (val) {
        this.blocksData = val
      }
    },
    methods: {
      toggleDrag (id) {
        if (id in this.openedBlocks) {
          delete this.openedBlocks[id]
        } else {
          this.openedBlocks[id] = true
        }

        this.enabled = Object.keys(this.openedBlocks).length === 0
      },
      // updateBlocks (data): Update all landingpage blocks with the correct order (position)
      updateBlocks () {
        const self = this
        this.enabled = false // Disable drag'n'drop while saving data

        const updateQuery = {}

        let count = 0
        this.blocksData.forEach((block) => {
          updateQuery[`landingpage.blocks.${block.key}.order`] = count
          count++
        })

        console.log(updateQuery)

        fb.prospectsCollection.doc(this.prospect._id).update(updateQuery).then(() => {
          self.$bvToast.toast(self.trans('prospects.landingpage.reorder_blocks_success'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
          this.enabled = true
        }).catch((err) => {
          self.$bvToast.toast(self.trans('prospects.landingpage.reorder_blocks_error'), {
            title: self.trans('global.error'),
            variant: 'warning',
            solid: true
          })
          console.log(err)
          this.enabled = true
        })
      }
    }
  }
</script>
