<template>
  <div>
    <b-form @submit.prevent>
      <b-modal id="add-room-modal" scrollable no-stacking size="lg">
        <template v-slot:modal-header>
          <h3>Legg til nytt rom</h3>
          <p class="description">
            Opprett et nytt rom i prospektet og designeren
          </p>
        </template>

        <template v-slot:default>
          <b-container class="full-width">
            <b-row>
              <b-col>
                <b-form-group
                  id="room_name_group"
                  label="Navn"
                  label-for="room_name"
                >
                  <b-form-input
                    id="room_name"
                    v-model="$v.roomForm.customname.$model"
                    name="room_name"
                    class="form-control-lg"
                    :state="formErrors.customname"
                    type="text"
                    placeholder="Skriv inn rommets navn"
                    aria-describedby="name-msg"
                    trim
                  ></b-form-input>

                  <b-form-invalid-feedback id="name-msg">
                    <span v-if="!$v.roomForm.customname.minlength">Rommets navn må inneholde minst 2 bokstaver</span>
                    <span v-if="!$v.roomForm.customname.required">Rommet må ha et navn</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="room_category_group" :label="trans('global.category')" label-for="room_category">
                  <b-form-select
                    id="room_category"
                    v-model="$v.roomForm.category.$model"
                    name="room_category"
                    size="lg"
                    class="form-control-lg"
                    :state="formErrors.category"
                    aria-describedby="category-msg"
                  >
                    <option :value="null">
                      {{ trans('rooms.room_category_desc') }}
                    </option>
                    <option value="1">
                      {{ trans('global.kitchen') }}
                    </option>
                    <option value="2">
                      {{ trans('global.kitchen_livingroom') }}
                    </option>
                    <option value="3">
                      {{ trans('global.livingroom') }}
                    </option>
                    <option value="4">
                      {{ trans('global.bedroom') }}
                    </option>
                    <option value="5">
                      {{ trans('global.bath') }}
                    </option>
                    <option value="6">
                      {{ trans('global.wc') }}
                    </option>
                    <option value="7">
                      {{ trans('global.laundryroom') }}
                    </option>
                    <option value="8">
                      {{ trans('global.wc_laundryroom') }}
                    </option>
                    <option value="9">
                      {{ trans('global.exterior') }}
                    </option>
                    <option value="10">
                      {{ trans('global.plot') }}
                    </option>
                    <option value="11">
                      {{ trans('global.hall') }}
                    </option>
                    <option value="12">
                      {{ trans('global.loft') }}
                    </option>
                  </b-form-select>
                  <b-form-invalid-feedback id="category-msg">
                    <span v-if="!$v.roomForm.category.required">{{ trans('rooms.form_add_new_errors.category') }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </template>

        <template v-slot:modal-footer="{ cancel }">
          <b-button variant="light" size="md" class="padding-left-60 padding-right-60 float-right margin-top-26" @click="cancel()">
            {{ trans('global.cancel') }}
          </b-button>
          <b-button
            v-if="submitStatus === 1"
            ref="submitbtn"
            variant="primary"
            size="md"
            class="padding-left-60 padding-right-60 margin-top-26"
            disabled
          >
            <b-spinner variant="white"></b-spinner>
          </b-button>
          <b-button
            v-else
            ref="submitbtn"
            variant="primary"
            size="md"
            class="padding-left-60 padding-right-60 margin-top-26"
            @click="addRoom"
          >
            <span>{{ trans('global.save') }}</span>
          </b-button>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { minLength, required } from 'vuelidate/lib/validators'

  const varyConfig = require('../../../../../../varyConfig')
  const fb = require('../../../../../../firebaseConfig')

  export default {
    name: 'ModalAddRoom',
    mixins: [validationMixin],
    props: {
      prospect: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        submitStatus: 0,
        submitted: false,
        btnWidth: 0,
        roomForm: {
          customname: '',
          category: null
        },
        roomCategories: varyConfig.roomCategories
      }
    },
    validations () {
      return {
        roomForm: {
          customname: {
            required,
            minlength: minLength(2)
          },
          category: {
            required
          }
        }
      }
    },
    computed: {
      nextRoomId () {
        const count = Object.keys(this.prospect.rooms).length
        console.log('Count = ', count)
        return count
      },
      formErrors () {
        return {
          customname: this.$v.roomForm.customname.$dirty && this.submitted ? !this.$v.roomForm.customname.$error : null,
          category: (this.$v.roomForm.category.$dirty && this.submitted) ? !this.$v.roomForm.category.$error : null
        }
      }
    },
    mounted () {
      this.$root.$on('bv::modal::hide', () => { })
    },
    methods: {
      addRoom () {
        const self = this
        this.submitStatus = 1
        this.submitted = true

        this.$v.roomForm.$touch()

        if (this.$v.roomForm.$anyError) {
          this.submitStatus = 0
          return
        }

        const nextRoomKey = parseInt(Object.keys(this.prospect.rooms).pop()) + 1

        fb.prospectsCollection.doc(this.prospect._id).update({
          [`rooms.${nextRoomKey}`]: {
            customname: this.roomForm.customname,
            category: this.roomForm.category,
            selected: 1,
            id: nextRoomKey
          }
        }).then(() => {
          self.$bvToast.toast(self.trans('prospects.designer.form_manage_rooms.update_success'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })

          this.submitStatus = 0
        }).catch((err) => {
          self.$bvToast.toast(self.trans('prospects.designer.form_manage_rooms.update_error'), {
            title: self.trans('global.error'),
            variant: 'warning',
            solid: true
          })

          console.log(err)
        }).finally(() => {
          this.$root.$emit('bv::hide::modal', 'add-room-modal')
        })
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
