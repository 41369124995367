<template>
  <div class="blockwrp">
    <div class="collapser">
      <SortableHandle :sortable="block.sortable"></SortableHandle>
      <b-link class="togglebar" @click="visible = !visible">
        <p>{{ trans('prospects.landingpage.blocks.' + block.name.toLowerCase()) }}</p>
      </b-link>

      <b-dropdown v-if="showActions" :id="`block-${block.key}`" variant="light" class="list-actions">
        <b-dropdown-item @click="deleteBlock">
          {{ trans('global.delete') }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="content">
      <b-collapse v-model="visible">
        <component :is="componentTargetFile" :sortable="block.sortable" :block="block" :prospect="prospect" :project="project"></component>
      </b-collapse>
    </div>
  </div>
</template>

<script>
  import SortableHandle from './SortableHandle'

  const fb = require('../../../../../../firebaseConfig')

  export default {
    name: 'Block',
    components: {
      SortableHandle
    },
    props: {
      block: {
        type: Object,
        required: true
      },
      prospect: {
        type: Object,
        required: true
      },
      project: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        visible: false
      }
    },
    computed: {
      componentTargetFile () {
        return () => import('./' + this.block.name + '/Index.vue')
      },
      showActions () {
        return this.block.actions !== undefined ? this.block.actions : true // Defualt: show actions
      }
    },
    watch: {
      visible () {
        this.$emit('dragenabled', this.block.name)
      }
    },
    methods: {
      deleteBlock () {
        this.$bvModal.msgBoxConfirm(this.trans('prospects.landingpage.delete_block_confirm', { item: this.block.name }), {
          title: this.trans('prospects.landingpage.delete_block_confirm'),
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: this.trans('global.delete'),
          cancelTitle: this.trans('global.cancel')
        }).then((value) => {
          if (value !== false) {
            fb.prospectsCollection.doc(this.prospect._id).update({
              [`landingpage.blocks.${this.block.key}.deleted`]: true
            }).then(() => {
              this.$bvToast.toast(this.trans('prospects.landingpage.delete_block_success'), {
                title: this.trans('global.success'),
                variant: 'success',
                solid: true
              })
            }).catch((err) => {
              this.$bvToast.toast(this.trans('prospects.landingpage.delete_block_error'), {
                title: this.trans('global.error'),
                variant: 'warning',
                solid: true
              })
              console.log('Error while deleting product')
              console.log(err)
            })
          } else {
            console.log('Delete was aborted')
          }
        }).catch((err) => {
          console.log('Error while deleting the block')
          console.log(err)
        })
      }
    }
  }
</script>
