<template>
  <b-container>
    <b-row>
      <b-col>
        <Headings type="h1" :title="trans('global.landingpage')" />
      </b-col>
    </b-row>
    <Block name="Carousel" :sortable="false" :block="lpBlocks.required !== undefined && lpBlocks.required[0] ? lpBlocks.required[0] : { name: 'Carousel', order: 0, actions: false }" :prospect="prospect" :project="project"></Block>
    <Block name="Intro" :sortable="false" :block="lpBlocks.required !== undefined && lpBlocks.required[1] ? lpBlocks.required[1] : { name: 'Intro', order: 1, actions: false }" :prospect="prospect" :project="project"></Block>
    <SortableBlocks v-if="lpBlocks.sortable" :blocks="lpBlocks.sortable" :prospect="prospect" :project="project"></SortableBlocks>
    <b-row>
      <b-col class="text-center">
        <b-button id="landingpage-block-selector" ref="button" variant="primary">
          {{ trans('prospects.landingpage.add_new_content') }}
        </b-button>

        <b-popover
          ref="popover"
          target="landingpage-block-selector"
          triggers="click"
          :show.sync="popoverShow"
          placement="auto"
        >
          <div class="landingpage-blocks">
            <p class="font-weight-bold">
              {{ trans('prospects.landingpage.choose_block') }}
            </p>
            <div class="blockswrp">
              <b-link :class="{ 'disabled': loading && !loadingIcons.includes('contactinfo') }" @click="addBlock('contactinfo')">
                <i class="fal fa-id-badge" :class="{ 'fa-spin': loadingIcons.includes('contactinfo') }"></i>
                <p>{{ trans('prospects.landingpage.blocks.contactinfo') }}</p>
              </b-link>
              <b-link :class="{ 'disabled': loading && !loadingIcons.includes('salestask') }" @click="addBlock('salestask')">
                <i class="fal fa-file-alt" :class="{ 'fa-spin': loadingIcons.includes('salestask') }"></i>
                <p>{{ trans('prospects.landingpage.blocks.salestask') }}</p>
              </b-link>
              <b-link :class="{ 'disabled': loading && !loadingIcons.includes('plans') }" @click="addBlock('plans')">
                <i class="fal fa-ruler-combined" :class="{ 'fa-spin': loadingIcons.includes('plans') }"></i>
                <p>{{ trans('prospects.landingpage.blocks.plans') }}</p>
              </b-link>
              <b-link :class="{ 'disabled': loading && !loadingIcons.includes('maps') }" @click="addBlock('maps')">
                <i class="fal fa-map-marker-alt" :class="{ 'fa-spin': loadingIcons.includes('maps') }"></i>
                <p>{{ trans('prospects.landingpage.blocks.maps') }}</p>
              </b-link>
              <b-link :class="{ 'disabled': loading && !loadingIcons.includes('tour') }" @click="addBlock('tour')">
                <i class="fal fa-layer-group" :class="{ 'fa-spin': loadingIcons.includes('tour') }"></i>
                <p>{{ trans('prospects.landingpage.blocks.tour') }}</p>
              </b-link>
            </div>
          </div>
        </b-popover>
      </b-col>
    </b-row>
    <b-row>
      <b-col style="margin-top:200px;">
        --
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { mapState } from 'vuex'
  import Headings from '../../../../Core/Heading'
  import Block from './Landingpage/Block'
  import SortableBlocks from './Landingpage/SortableBlocks'

  const fb = require('../../../../../firebaseConfig')

  export default {
    name: 'ProjectSalesProspectLandingpage',
    components: {
      Headings,
      Block,
      SortableBlocks
    },
    props: {
      prospect: {
        type: Object,
        required: true
      },
      project: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        popoverShow: false,
        loadingIcons: [],
        loading: false
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile
      }),
      // lpBlocks (): Needs to be an array to work with the VueDraggable plugin
      lpBlocks () {
        if (this.prospect.landingpage !== undefined && this.prospect.landingpage.blocks !== undefined) {
          const blocksArray = []
          const requiredBlocksArray = []
          Object.keys(this.prospect.landingpage.blocks).map((objectKey) => {
            const objectData = this.prospect.landingpage.blocks[objectKey]
            if (objectData.deleted === undefined || objectData.deleted === false) {
              objectData.key = objectKey
              if (objectData.name !== 'Carousel' && objectData.name !== 'Intro') {
                blocksArray.push(objectData)
              } else {
                let order = 0 // Default carousel order
                if (objectData.name === 'Intro') {
                  order = 1
                }
                objectData.order = order
                requiredBlocksArray.push(objectData)
              }
            } // else - deleted product
          })

          return {
            sortable: blocksArray.sort((a, b) => {
              if (a.order !== undefined && b.order !== undefined) {
                return a.order - b.order
              } else {
                return false
              }
            }),
            required: requiredBlocksArray.sort((a, b) => {
              if (a.order !== undefined && b.order !== undefined) {
                return a.order - b.order
              } else {
                return false
              }
            })
          }
        } else {
          return false
        }
      }
    },
    methods: {
      onClose () {
        this.popoverShow = false
      },
      addBlock (name) {
        const self = this
        const nextBlockId = this.prospect.landingpage !== undefined &&
          this.prospect.landingpage.blocks !== undefined &&
          Object.keys(this.prospect.landingpage.blocks).length > 0
          ? parseInt(Object.keys(this.prospect.landingpage.blocks).pop()) + 1
          : 0

        if (this.prospect._id !== undefined && !this.loading) {
          this.loading = true
          this.loadingIcons.push(name)

          fb.prospectsCollection.doc(this.prospect._id).update({
            [`landingpage.blocks.${nextBlockId}`]: {
              createdOn: new Date(),
              deleted: false,
              name: name.charAt(0).toUpperCase() + name.slice(1), // Ensure correct naming (camel)
              sortable: true,
              actions: true,
              userId: this.userProfile.uid
            }
          }).then(() => {
            self.$bvToast.toast(self.trans('prospects.landingpage.blocks.add_success'), {
              title: self.trans('global.success'),
              variant: 'success',
              solid: true
            })
            this.loading = false
            this.popoverShow = false
            this.loadingIcons = []
          }).catch((err) => {
            self.$bvToast.toast(self.trans('prospects.landingpage.blocks.add_error'), {
              title: self.trans('global.error'),
              variant: 'warning',
              solid: true
            })
            console.log(err)
            this.loading = false
            this.popoverShow = false
            this.loadingIcons = []
          })
        } else {
          // Errorhandling goes here
          console.log('ERROR: prospect._id was not found, or anotherone is loading...')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .landingpage-blocks {
    .blockswrp {
      display: flex;
      flex-wrap: wrap;
      margin: -10px;

      a {
        flex: 1 1 60px;
        margin: 10px;
        text-align: center;
        color: #333;
        padding: 10px;
        text-decoration: none;
        border-radius: 6px;

        i {
          font-size: 24px;
          margin-bottom: 10px;
        }

        p {
          font-size: 12px;
          margin: 0;
        }

        &:hover {
          color: #551A41;
          background-color: #f6f6f6;
        }

        &.disabled {
          cursor: pointer;
          opacity: 0.5;
        }
      }
    }
  }
</style>
