<template>
  <div>
    <b-modal id="add-room-category-modal" scrollable size="lg" @show="buildFolderStructure">
      <template v-slot:modal-header>
        <h3>{{ trans('prospects.designer.form_manage_folders.title') }}</h3>
        <p class="description">
          {{ trans('prospects.designer.form_manage_folders.desc') }}
        </p>
      </template>

      <template v-slot:default>
        <b-tabs card>
          <b-tab no-body title="Detaljer">
            <b-container class="full-width" style="overflow: hidden;">
              <b-table
                id="roomcategories"
                primary-key="catkey"
                hover
                striped
                :items="tableData"
                :fields="tableFields"
                :no-local-sorting="true"
              >
                <template v-slot:head(actions)="row">
                  <b-col class="text-right">
                    {{ row.label }}
                  </b-col>
                </template>
                <template v-slot:cell(name)="row">
                  <span v-if="!editCategory[row.item.catkey]">{{ row.item.name }}</span>
                  <b-form v-else inline @submit.prevent>
                    <b-form-input
                      :ref="'catname-' + row.item.catkey"
                      :value="row.item.name"
                      :v-model="row.item.name"
                      class="form-control-lg"
                      type="text"
                      trim
                    ></b-form-input>
                    <b-button :ref="'catsubmitbtn-' + row.item.catkey" variant="primary" class="margin-left-8" :style="btnWidth !== 0 ? 'width: ' + btnWidth + 'px;' : ''" @click="updateCatName(row.item.catkey)">
                      <span v-if="!buttonIsLoading[row.item.catkey]">{{ trans('global.save') }}</span>
                      <b-spinner v-else variant="white"></b-spinner>
                    </b-button>
                    <b-button variant="light" class="margin-left-8" @click="handleCatForm(row.item.catkey, false)">
                      {{ trans('global.cancel') }}
                    </b-button>
                  </b-form>
                </template>

                <!-- <template v-slot:cell(order)="row">
                  <b-form inline @submit.prevent>
                    <b-form-input :value="row.item.order" class="form-control-lg" type="text" trim @keyup="updateCatOrder(row.item.catkey, $event.target.value)"></b-form-input>
                  </b-form>
                </template> -->

                <template v-slot:cell(icon)="row">
                  <b-form inline @submit.prevent>
                    <b-form-input :value="row.item.icon" class="form-control-lg" type="text" trim @blur="updateCatIcon(row.item.catkey, $event.target.value)"></b-form-input>
                  </b-form>
                </template>

                <template v-slot:cell(type)="row">
                  <b-form-select
                    style="width: 100px"
                    :value="row.item.type"
                    :options="catTypes"
                    class="form-control-lg"
                    @change="updateCatType(row.item.catkey, $event)"
                  ></b-form-select>
                </template>

                <template v-slot:cell(image)="row">
                  <div class="render-wrapper overlay-wrapper" :style="row.item.image ? 'background-image: url(' + row.item.image + '?fit=crop&w=50);' : '' ">
                    <div class="radial-progress-wrp">
                      <RadialProgressBar
                        v-if="uploadingProductImage.includes(row.item.catkey)"
                        :diameter="20"
                        :completed-steps="progresses[row.item.catkey]"
                        :total-steps="100"
                        :stroke-width="2"
                        inner-stroke-color="#eaeaea"
                        :animate-speed="200"
                      >
                        <p>{{ Math.round(progresses[row.item.catkey]) }}</p>
                      </RadialProgressBar>
                    </div>

                    <b-form class="w-100 h-100" @submit.prevent>
                      <label class="select-label">
                        <span v-if="row.item.image !== false">
                          <i v-if="!uploadingProductImage.includes(row.item.catkey)" class="fal fa-retweet toggleme"></i>
                        </span>
                        <span v-else class="text-muted">
                          <p v-if="!uploadingProductImage.includes(row.item.catkey)">{{ trans('prospects.designer.no_folder_img') }}</p>
                        </span>
                        <input type="file" @change="uploadCategoryImage($event, row.item.catkey, 'image')" />
                      </label>
                    </b-form>
                  </div>
                </template>

                <template v-slot:cell(actions)="row">
                  <b-col class="text-right">
                    <b-dropdown size="md" variant="primary" no-caret right>
                      <template v-slot:button-content>
                        <i class="fas fa-caret-down"></i>
                      </template>
                      <b-dropdown-item @click="handleCatForm(row.item.catkey, true)">
                        {{ trans('global.edit') }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="openDeleteCategoryModal(row.item.catkey)">
                        {{ trans('global.delete') }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </template>
              </b-table>
              <b-table-simple hover striped>
                <b-tbody v-if="newCategories">
                  <b-tr v-for="(category, index) in newCategories" :key="index + '_' + category">
                    <b-td>
                      <b-form class="add_category" @submit.prevent>
                        <b-form-input
                          :id="index + '_newCategory'"
                          v-model="newCategories[index].title"
                          :name="index + '_newCategory'"
                          class="form-control-lg margin-right-26"
                          type="text"
                          :placeholder="trans('prospects.designer.form_add_room_category.add_placeholder')"
                          trim
                        ></b-form-input>
                        <b-button variant="primary" side="lg" class="padding-left-26 padding-right-26" @click="saveCategory(index)">
                          {{ trans('global.save') }}
                        </b-button>
                      </b-form>
                    </b-td>
                  </b-tr>
                </b-tbody>
                <div v-if="showError" class="alert alert-warning margin-top-26">
                  <i class="fas fa-exclamation-triangle padding-right-6"></i> {{ trans('prospects.designer.form_add_room_category.no_categories') }}
                </div>
                <b-button variant="primary" size="md" class="padding-left-60 padding-right-60 float-right margin-top-26 mr-4" @click="addCategory()">
                  {{ trans('prospects.designer.form_add_room_category.add') }}
                </b-button>
              </b-table-simple>
            </b-container>
          </b-tab>
          <b-tab no-body title="Struktur">
            <vue-nestable
              v-model="nestableItems"
              @change="saveFolderStructure"
            >
              <vue-nestable-handle
                slot-scope="{ item }"
                :item="item"
                class="d-flex align-items-center"
              >
                <i class="fas fa-folder mr-2" style="display: block; color: #c7c7c7;"></i>
                <span>{{ item.text }}</span>
              </vue-nestable-handle>
            </vue-nestable>
          </b-tab>
        </b-tabs>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="light" size="md" class="padding-left-60 padding-right-60 float-right margin-top-26" @click="cancel()">
          {{ trans('global.close') }}
        </b-button>
      </template>
    </b-modal>
    <ModalDeleteCategory :prospect="prospect" :roomkey="roomkey" :categories="categories !== false ? categories : {}" :categorykey="currentCategoryKey" :roomproducts="roomproducts[roomkey]"></ModalDeleteCategory>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { VueNestable, VueNestableHandle } from 'vue-nestable'
  import * as objectPath from 'object-path'

  import RadialProgressBar from '../../../../../Core/RadialProgressBar'

  import ModalDeleteCategory from './DeleteCategory'

  const fb = require('../../../../../../firebaseConfig')
  const varyConfig = require('../../../../../../varyConfig')

  export default {
    name: 'ModalAddRoomCategory',
    components: {
      ModalDeleteCategory,
      VueNestable,
      VueNestableHandle,
      RadialProgressBar
    },
    props: {
      project: {
        type: Object,
        required: true
      },
      prospect: {
        type: Object,
        required: true
      },
      roomkey: {
        type: Number,
        required: true
      },
      roomproducts: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        nestableItems: [],
        loading: false,
        categoryForm: {
          title: ''
        },
        newCategories: {},
        enabledCategories: [],
        btnLoading: [],
        btnWidth: 0,
        timer: [],
        currentCategoryKey: '',
        catTypes: [
          { value: null, text: 'Vanlig' },
          { value: 1, text: 'Tekst' },
          { value: 2, text: 'Liste' },
          { value: 3, text: 'Skjult' }
        ],
        folderStructureAddedKeys: [],
        folderStructurePath: [],
        folderStructureMaxRuns: 40,
        uploadingProductImage: [], // product images beeing uploaded for individual loading anims
        progresses: [],
        progress: 0,
        roomCategories: varyConfig.roomCategories
      }
    },
    computed: {
      showError () {
        return !((this.prospect.rooms[this.roomkey].categories !== undefined && Object.keys(this.prospect.rooms[this.roomkey].categories).length > 0) || Object.keys(this.newCategories).length > 0)
      },
      categories () {
        let cats = this.prospect.rooms[this.roomkey].categories !== undefined && Object.keys(this.prospect.rooms[this.roomkey].categories).length > 0 ? this.prospect.rooms[this.roomkey].categories : {}

        // Remove deleted categories from view
        if (Object.keys(cats).length > 0) {
          const catsCopy = { ...cats }
          Object.keys(catsCopy).forEach((catKey) => {
            if (catsCopy[catKey].deleted !== undefined && catsCopy[catKey].deleted === true) {
              delete catsCopy[catKey]
            }
          })

          cats = catsCopy
        }

        return cats
      },
      tableFields () {
        return [
          { key: 'name', label: this.trans('global.name') },
          { key: 'icon', label: this.trans('global.icon') },
          { key: 'type', label: 'Visning', class: 'typefield' },
          { key: 'image', label: 'Bilde' },
          { key: 'actions', label: 'Actions' }
        ]
      },
      tableData () {
        const data = []

        Object.keys(this.categories).map((categoryKey) => {
          const catData = this.categories[categoryKey]
          if (catData.deleted === undefined || catData.deleted !== true) {
            // Category image
            let imgData = false
            if (catData.image !== undefined && typeof catData.image === 'object' && catData.image !== null) {
              imgData = process.env.VUE_APP_IMAGE_CDN_URL + '/' + catData.image.path + '/' + catData.image.file
            } else if (typeof catData.image === 'string') {
              imgData = process.env.VUE_APP_IMAGE_CDN_URL + '/' + catData.image
            } else {
              imgData = false
            }

            data.push({
              catkey: categoryKey,
              name: catData.title,
              icon: catData.icon !== undefined ? catData.icon : '',
              image: imgData,
              type: catData.type !== undefined ? catData.type : null
            })
          }
        })

        return data
      },
      editCategory () {
        const categories = {}

        Object.keys(this.categories).map((categoryKey) => {
          categories[categoryKey] = this.enabledCategories.includes(categoryKey)
        })

        return categories
      },
      buttonIsLoading () {
        const buttons = {}

        Object.keys(this.categories).map((categoryKey) => {
          buttons[categoryKey] = this.btnLoading.includes(categoryKey)
        })

        return buttons
      }
    },
    mounted () {
      this.$root.$on('bv::modal::hide', () => {
        this.newCategories = {}
        this.nestableItems = []
        this.folderStructureMaxRuns = 90
        this.folderStructureAddedKeys = []
      })
    },
    methods: {
      // uploadFolderImage (e, catKey): Uploads and updates the category image
      // Type: 'render' or 'image' or 'overlay'
      uploadCategoryImage (e, catKey, type) {
        // TODO: make the same function for updateImage (room image) and updateProductImage
        const imageData = e.target.files[0]

        if (imageData !== undefined && typeof imageData === 'object' && imageData.name !== undefined && type !== undefined) {
          this.uploadingProductImage.push(catKey)
          const self = this
          const room = this.prospect.rooms[this.roomkey]
          // const product = room.products[catKey]

          let randomTxt = ''
          const length = 16
          const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

          for (let i = 0; i < length; i++) {
            randomTxt += possible.charAt(Math.floor(Math.random() * possible.length))
          }

          const storageRef = fb.storage.ref()
          const metadata = {
            contentType: imageData.type
          }

          const newFilename = randomTxt + `_catKey-${catKey}.` + imageData.name.split('.').pop()
          const fileOrgName = imageData.name
          const imageTargetPath = 'projects/' + this.project.varycode + '/prospects/' + this.prospect.varycode + '/rooms/' + room.id + '_' + this.roomCategories[room.category] + '/categories'

          const uploadTask = storageRef.child(imageTargetPath + '/' + newFilename).put(imageData, metadata) // Upload the file

          uploadTask.on('state_changed', function (snapshot) {
            Vue.set(self.progresses, catKey, (snapshot.bytesTransferred / snapshot.totalBytes) * 100)

            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
            }
          }, function (error) {
            switch (error.code) {
              case 'storage/unauthorized':
                console.log('Storage autorization required, access denied...')
                break
              case 'storage/canceled':
                console.log('The upload was cancelled')
                break
              case 'storage/unknown':
                console.log('An unknown error occurred during upload')
                break
            }
          }, function () {
            self.uploadingProductImage = self.uploadingProductImage.filter(item => item !== catKey)

            fb.prospectsCollection.doc(self.prospect._id).update({
              [`rooms.${self.roomkey}.categories.${catKey}.image`]: {
                createdOn: new Date(),
                file: newFilename,
                orgFile: fileOrgName,
                path: imageTargetPath,
                meta: metadata,
                userId: self.$store.state.auth.userProfile.uid,
                deleted: false
              }
            }).then(() => {
              self.$bvToast.toast(self.trans('prospects.designer.image_uploaded_successfully'), {
                title: self.trans('global.success'),
                variant: 'success',
                solid: true
              })
            }).catch((err) => {
              // Do errorhandling here
              console.log('Error while updating room image:')
              console.log(err)
            })
          })
        } else {
          // Do error-handling here
          console.log('Error: Unable to find the image data in the form.')
        }
      },
      saveFolderStructure (el) {
        const self = this

        // Check for parent and find parent key
        const parent = this.findFolderPositionAndParent(el.key, this.nestableItems)

        console.log('Saving: ', parent)

        // Save the choices to the db
        fb.prospectsCollection.doc(this.prospect._id).update({
          [`rooms.${self.roomkey}.categories.${el.key}.order`]: objectPath.get(parent, 'pIdx', null),
          [`rooms.${self.roomkey}.categories.${el.key}.parentKey`]: objectPath.get(parent, 'pKey', null)
        }).then(() => {
          console.log('Category was updated...')
        }).catch((err) => {
          console.log('Errors when updating category: ', err)
        })
      },
      findFolderPositionAndParent (key, sourceArray, parentKey = null) {
        let pKey = null
        let pIdx = null

        for (const i in sourceArray) {
          const sourceKey = objectPath.get(sourceArray, `${i}.key`, null)
          const children = objectPath.get(sourceArray, `${i}.children`, [])

          if (key === sourceKey) {
            pKey = parentKey
            pIdx = i
            break
          }

          if (children.length > 0 && pKey === null && pIdx === null) {
            ({ pKey, pIdx } = this.findFolderPositionAndParent(key, children, sourceKey))
          }
        }

        return { pKey, pIdx }
      },
      buildFolderStructure () {
        const foldersObject = { ...this.categories }
        let reRun = false

        this.folderStructureMaxRuns = this.folderStructureMaxRuns - 1
        if (this.folderStructureMaxRuns <= 0) return

        for (const folderKey in foldersObject) {
          const currentFolder = foldersObject[folderKey]

          const folderData = {
            id: objectPath.get(currentFolder, 'key', null),
            key: objectPath.get(currentFolder, 'key', null),
            text: objectPath.get(currentFolder, 'title', '(ukjent tittel)'),
            order: objectPath.get(currentFolder, 'order', null),
            parentKey: objectPath.get(currentFolder, 'parentKey', null)
          }

          if (!this.folderStructureAddedKeys.includes(currentFolder.key)) {
            if (currentFolder.parentKey === undefined || currentFolder.parentKey === null) {
              this.nestableItems.push(folderData) // Add item to "root" folder-structure
              this.folderStructureAddedKeys.push(objectPath.get(currentFolder, 'key', null)) // Save folder-KEY to avoid adding folders multiple times
            } else {
              // Find path to the parent folder
              const folderPath = this.findFolderParentPath(currentFolder.parentKey)

              if (folderPath) {
                // Found a valid path
                const nestableItem = this.getFolderArrayValue(this.nestableItems, this.folderStructurePath)

                if (nestableItem) {
                  // Adding child
                  if (nestableItem.children === undefined) {
                    nestableItem.children = []
                  }

                  nestableItem.children.push(folderData)
                  this.folderStructureAddedKeys.push(objectPath.get(currentFolder, 'key', null))
                }
              } else {
                // Not found, probably not added to the nestableItems array yet, reRun the function
                reRun = true
              }
            }
          }

          this.folderStructurePath = [] // Reset the folderPath

          // Sort the nestable-array
          this.nestableItems = this.nestableItems.sort((a, b) => {
            if (a.order !== undefined && b.order !== undefined) {
              return (a.order === null) - (b.order === null) || +(a.order > b.order) || -(a.order < b.order)
            } else {
              return false
            }
          })
        }

        if (reRun) this.buildFolderStructure()
      },
      findFolderParentPath (parentKey, sourceArray = this.nestableItems, fullPath = []) {
        let foundPath = false

        for (const arrayIdx in sourceArray) {
          const folderKey = objectPath.get(sourceArray, `${arrayIdx}.key`, null)
          const children = objectPath.get(sourceArray, `${arrayIdx}.children`, [])

          if (parentKey === folderKey) {
            if (fullPath.length > 0) this.folderStructurePath = this.folderStructurePath.concat(fullPath)
            this.folderStructurePath.push(arrayIdx)
            fullPath = []
            foundPath = true
            break
          } else if (children.length > 0) {
            fullPath.push(arrayIdx)
            foundPath = this.findFolderParentPath(parentKey, children, fullPath)
            if (!foundPath) fullPath = [] // required!
          } else {
            fullPath = [] // reset the fullpath if no children
          }
        }

        return foundPath ? this.folderStructurePath : false
      },
      getFolderArrayValue (base, pathStructure) {
        for (let i = 0; i < pathStructure.length; i++) {
          if (i === 0) {
            base = base[pathStructure[i]]
          } else {
            base = base.children[pathStructure[i]]
          }
        }

        return base
      },
      addCategory () {
        Vue.set(this.newCategories, Object.keys(this.newCategories).length, { title: '' })
      },
      saveCategory (i) {
        const self = this

        const id = this.prospect.rooms[this.roomkey].categories !== undefined ? parseInt(Object.keys(this.prospect.rooms[this.roomkey].categories).pop()) + 1 : 0

        fb.prospectsCollection.doc(this.prospect._id).update({
          [`rooms.${self.roomkey}.categories.${id}`]: {
            createdOn: new Date(),
            title: this.newCategories[i].title,
            userId: self.$store.state.auth.userProfile.uid
          }
        }).then(() => {
          Vue.delete(this.newCategories, i)

          self.$bvToast.toast(self.trans('prospects.designer.form_add_room_category.add_success'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
        }).catch((err) => {
          // Do errorhandling here
          console.log('There was a error while saving the category')
          console.log(err)
        })

        return true
      },
      handleCatForm (id, enable) {
        if (enable) {
          this.enabledCategories.push(id)
        } else {
          this.enabledCategories = this.enabledCategories.filter((item) => {
            return item !== id
          })
        }
      },
      handleCatLoading (id, enable) {
        this.btnWidth = this.$refs['catsubmitbtn-' + id].offsetWidth

        if (enable) {
          this.btnLoading.push(id)
        } else {
          this.btnLoading = this.btnLoading.filter((item) => {
            return item !== id
          })
        }
      },
      updateCatType (catKey, value) {
        const self = this

        if (catKey !== undefined && value !== undefined) {
          fb.prospectsCollection.doc(this.prospect._id).update({
            [`rooms.${self.roomkey}.categories.${catKey}.type`]: value
          }).then(() => {
            this.$bvToast.toast(this.trans('prospects.designer.product_updated', { product: self.prospect.rooms[self.roomkey].categories[catKey].title }), {
              title: this.trans('global.success'),
              variant: 'success',
              solid: true
            })
          }).catch((err) => {
            this.$bvToast.toast(this.trans('prospects.designer.product_updated', { product: self.prospect.rooms[self.roomkey].categories[catKey].title }), {
              title: this.trans('global.error'),
              variant: 'warning',
              solid: true
            })
            console.log(err)
          })
        }
      },
      updateCatName (catKey) {
        const self = this
        const value = this.$refs['catname-' + catKey].localValue

        if (catKey !== undefined && value !== undefined) {
          this.handleCatLoading(catKey, true)
          const updatedId = this.prospect.rooms[this.roomkey].categories[catKey] !== undefined && this.prospect.rooms[this.roomkey].categories[catKey].updated !== undefined && Object.keys(this.prospect.rooms[this.roomkey].categories[catKey].updated).length > 0 ? parseInt(Object.keys(this.prospect.rooms[this.roomkey].categories[catKey].updated).pop()) + 1 : 0

          const updatedInfo = {}
          updatedInfo.userId = self.$store.state.auth.userProfile.uid
          updatedInfo.createdOn = new Date()
          fb.prospectsCollection.doc(this.prospect._id).update({
            [`rooms.${self.roomkey}.categories.${catKey}.title`]: value,
            [`rooms.${self.roomkey}.categories.${catKey}.updated.${updatedId}`]: updatedInfo
          }).then(() => {
            self.$bvToast.toast(self.trans('prospects.designer.form_edit_room_category.update_success'), {
              title: self.trans('global.success'),
              variant: 'success',
              solid: true
            })
            self.handleCatForm(catKey, false)
            self.handleCatLoading(catKey, false)
          }).catch((err) => {
            self.$bvToast.toast(self.trans('prospects.designer.form_edit_room_category.update_error'), {
              title: self.trans('global.error'),
              variant: 'warning',
              solid: true
            })

            console.log(err)

            self.handleCatForm(catKey, false)
            self.handleCatLoading(catKey, false)
          })
        }

        return true
      },
      updateCatOrder (catKey, value) {
        const self = this

        if (catKey !== undefined && value !== undefined) {
          this.inputTimer(catKey).then(() => {
            fb.prospectsCollection.doc(this.prospect._id).update({
              [`rooms.${self.roomkey}.categories.${catKey}.order`]: value
            }).then(() => {
              this.$bvToast.toast(this.trans('prospects.designer.product_updated', { product: self.prospect.rooms[self.roomkey].categories[catKey].title }), {
                title: this.trans('global.success'),
                variant: 'success',
                solid: true
              })
            }).catch((err) => {
              this.$bvToast.toast(this.trans('prospects.designer.product_updated', { product: self.prospect.rooms[self.roomkey].categories[catKey].title }), {
                title: this.trans('global.error'),
                variant: 'warning',
                solid: true
              })
              console.log(err)
            })
          })
        }
      },
      updateCatIcon (catKey, value) {
        const self = this

        if (catKey !== undefined && value !== undefined && value !== '') {
          this.inputTimer(catKey).then(() => {
            fb.prospectsCollection.doc(this.prospect._id).update({
              [`rooms.${self.roomkey}.categories.${catKey}.icon`]: value
            }).then(() => {
              this.$bvToast.toast(this.trans('prospects.designer.product_updated', { product: self.prospect.rooms[self.roomkey].categories[catKey].title }), {
                title: this.trans('global.success'),
                variant: 'success',
                solid: true
              })
            }).catch((err) => {
              this.$bvToast.toast(this.trans('prospects.designer.product_updated', { product: self.prospect.rooms[self.roomkey].categories[catKey].title }), {
                title: this.trans('global.error'),
                variant: 'warning',
                solid: true
              })
              console.log(err)
            })
          })
        }
      },
      inputTimer (catKey) {
        return new Promise((resolve) => {
          if (this.timer[catKey]) {
            clearTimeout(this.timer[catKey])
            this.timer[catKey] = null
          }

          this.timer[catKey] = setTimeout(() => {
            resolve()
          }, 600)
        })
      },
      openDeleteCategoryModal (catKey) {
        this.currentCategoryKey = catKey
        this.$bvModal.show('delete-category-modal')
      }
      // deleteCategory (catKey) {
      //
      // }
    }
  }
</script>

<style lang="scss" scoped>
  .add_category {
    display: flex;
  }

  /deep/ #add-room-category-modal {
    .modal-body {
      padding: 0 !important;
    }

    /*
    * Style for nestable
    */
    .nestable {
      position: relative;
      padding: 10px;
    }
    .nestable-rtl {
      direction: rtl;
    }
    .nestable .nestable-list {
      margin: 0;
      padding: 0 0 0 20px;
      list-style-type: none;
    }
    .nestable-rtl .nestable-list {
      padding: 0 40px 0 0;
    }
    .nestable > .nestable-list {
      padding: 0;
    }

    .nestable-item {
      padding: 4px 0 4px 10px;
      border-radius: 2px;
      background-color: #e9e9e9;
    }

    .nestable-item,
    .nestable-item-copy {
      // margin: 10px 0 0;
      margin: 2px 2px 2px 2px;
    }
    .nestable-item:first-child,
    .nestable-item-copy:first-child {
      margin-top: 0;
    }
    .nestable-item .nestable-list,
    .nestable-item-copy .nestable-list {
      margin-top: 10px;
    }
    .nestable-item {
      position: relative;
    }
    .nestable-item.is-dragging .nestable-list {
      pointer-events: none;
    }
    .nestable-item.is-dragging * {
      opacity: 0;
      filter: alpha(opacity=0);
    }
    .nestable-item.is-dragging:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(106, 127, 233, 0.274);
      border: 1px dashed rgb(73, 100, 241);
      -webkit-border-radius: 5px;
      border-radius: 5px;
    }
    .nestable-drag-layer {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      pointer-events: none;
    }
    .nestable-rtl .nestable-drag-layer {
      left: auto;
      right: 0;
    }
    .nestable-drag-layer > .nestable-list {
      position: absolute;
      top: 0;
      left: 0;
      padding: 0;
      background-color: rgba(106, 127, 233, 0.274);
    }
    .nestable-rtl .nestable-drag-layer > .nestable-list {
      padding: 0;
    }
    .nestable [draggable="true"] {
      cursor: move;
    }
    .nestable-handle {
      display: inline;
    }
  }

  #roomcategories {

    /deep/ thead {
      tr {
        th {
          border: none;
        }
      }
    }

    /deep/ tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }

  .render-wrapper {
    position: relative;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 4px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fff;
    overflow: hidden;

    &.overlay-wrapper {
      width: 80px;
      height: 80px;
    }

    .select-label {
      width: 100%;
      height: 100%;
      margin-bottom: 0;

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      transition-duration: .4s;

      &:hover {
        background-color: rgba(235, 235, 235, 0.8);

        > span {
          i {
            &.toggleme {
              display: inline-block;
            }
          }
        }
      }

      > span {
        text-align: center;

        i {
          font-size: 26px;

          &.toggleme {
            display: none;
          }
        }

        p {
          font-size: 12px;
          margin-bottom: 0;
          font-weight: 400;
        }
      }

      > input[type="file"] {
        display: none;
      }
    }

    .radial-progress-wrp {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -25px;
      margin-left: -25px;

      p {
        margin-bottom: 0;
      }
    }
  }
</style>
