<template>
  <div>
    <b-form @submit.prevent>
      <b-modal
        id="add-room-new-product-modal"
        size="lg"
        body-class="formified"
        footer-class="loadingfix"
        no-stacking
        scrollable
      >
        <template v-slot:modal-header>
          <h3>{{ trans('prospects.designer.form_add_room_product.add') }}</h3>
          <p class="description">
            {{ trans('prospects.designer.form_add_room_product.add_description') }}
          </p>
        </template>

        <template v-slot:default>
          <b-row>
            <b-col>
              <div class="modal-header-wrp">
                <h4>{{ trans('prospects.designer.form_add_room_product.add_new') }}</h4>
                <b-button v-b-modal.add-room-product-modal variant="primary" size="sm" class="padding-left-16 padding-right-16">
                  {{ trans('prospects.designer.form_add_room_product.add_existing') }}
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="flex-column">
            <b-col sm="6">
              <b-form-group id="product_image_group" :label="trans('prospects.designer.form_add_room_new_product.image')" label-for="product_image">
                <div :class="(!productImageStyle)? 'noimg' : ''" class="vary-image-container" :style="productImageStyle">
                  <div v-if="!productImageStyle" class="text">
                    <i class="fas fa-file-image" style="font-size: 40px;"></i>
                    <span>{{ trans('prospects.designer.form_add_room_new_product.image_description') }}</span>
                  </div>
                </div>
                <b-form-file
                  id="product_image"
                  ref="productimage"
                  v-model="productForm.image"
                  :placeholder="trans('prospects.designer.form_add_room_new_product.image_placeholder')"
                  class="margin-top-16"
                  accept="image/jpeg, image/png"
                >
                </b-form-file>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="product_name_group" :label="trans('prospects.designer.form_add_room_new_product.name')" label-for="product_name">
                <b-form-input
                  id="product_name"
                  v-model="$v.productForm.name.$model"
                  name="product_name"
                  class="form-control-lg"
                  :state="formErrors.name"
                  type="text"
                  :placeholder="trans('prospects.designer.form_add_room_new_product.name_description')"
                  aria-describedby="name-msg"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="name-msg">
                  <span v-if="!$v.productForm.name.minlength">{{ trans('prospects.designer.form_add_room_new_product.name_min_length') }}</span>
                  <span v-if="!$v.productForm.name.required">{{ trans('prospects.designer.form_add_room_new_product.name_required') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="product_shorttxt_group"
                label="Produktbeskrivelse"
                label-for="product_shorttxt"
                class="vary-wysiwyg"
              >
                <!--                <b-form-textarea-->
                <!--                  id="product_shorttxt"-->
                <!--                  v-model="productForm.shorttxt"-->
                <!--                  name="product_shorttxt"-->
                <!--                  class="form-control-lg"-->
                <!--                  :placeholder="trans('prospects.designer.form_add_room_new_product.short_information_description')"-->
                <!--                  rows="2"-->
                <!--                  max-rows="4"-->
                <!--                ></b-form-textarea>-->
                <editor-menu-bubble
                  v-slot="{ commands, isActive, getMarkAttrs, menu }"
                  class="menububble"
                  :editor="editor"
                  @hide="hideLinkMenu"
                >
                  <div
                    class="menububble"
                    :class="{ 'is-active': menu.isActive }"
                    :style="`left: ${menu.left + 80}px; bottom: ${menu.bottom}px;`"
                  >
                    <form
                      v-if="linkMenuIsActive"
                      class="menububble__form"
                      @submit.prevent="setLinkUrl(commands.link, linkUrl)"
                    >
                      <input
                        ref="linkInput"
                        v-model="linkUrl"
                        class="menububble__input"
                        type="text"
                        placeholder="https://"
                        @keydown.esc="hideLinkMenu"
                      />
                      <button
                        class="menububble__button"
                        type="button"
                        @click="setLinkUrl(commands.link, null)"
                      >
                        <Icon icon="remove" />
                      </button>
                    </form>

                    <template v-else>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.link() }"
                        @click="showLinkMenu(getMarkAttrs('link'))"
                      >
                        <span>{{ isActive.link() ? 'Update Link' : 'Add Link' }}</span>
                        <Icon icon="link" />
                      </button>
                    </template>
                  </div>
                </editor-menu-bubble>

                <editor-menu-bar v-slot="{ commands, isActive }" class="menubar" :editor="editor">
                  <b-button-group>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                      @click="commands.heading({ level: 1 })"
                    >
                      H1
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                      @click="commands.heading({ level: 2 })"
                    >
                      H2
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                      @click="commands.heading({ level: 3 })"
                    >
                      H3
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <Icon icon="bold"></Icon>
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <Icon icon="italic"></Icon>
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.strike() }"
                      @click="commands.strike"
                    >
                      <Icon icon="strike"></Icon>
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <Icon icon="underline"></Icon>
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bullet_list() }"
                      @click="commands.bullet_list"
                    >
                      <Icon icon="ul"></Icon>
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.ordered_list() }"
                      @click="commands.ordered_list"
                    >
                      <Icon icon="ol"></Icon>
                    </button>
                  </b-button-group>
                </editor-menu-bar>
                <editor-content class="editor" :editor="editor" />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group id="product_longtxt_group" label="Ekstra informasjon" label-for="product_longtxt">
                <b-form-textarea
                  id="product_longtxt"
                  v-model="productForm.longtxt"
                  name="product_longtxt"
                  class="form-control-lg"
                  :placeholder="trans('prospects.designer.form_add_room_new_product.long_information_description')"
                  rows="6"
                  max-rows="12"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <template v-slot:modal-footer="{ cancel }">
          <div v-if="uploadStatus === 1" class="vary-image-progress">
            <b-progress :value="Math.round(progress)" variant="primary" striped :animated="true" show-progress></b-progress>
          </div>
          <b-button variant="light" size="md" class="padding-left-60 padding-right-60" @click="cancel()">
            {{ trans('global.cancel') }}
          </b-button>
          <b-button
            v-if="submitStatus === 1"
            ref="submitbtn"
            variant="primary"
            size="md"
            class="padding-left-60 padding-right-60"
            disabled
          >
            <b-spinner variant="white"></b-spinner>
          </b-button>
          <b-button
            v-else
            ref="submitbtn"
            variant="primary"
            size="md"
            class="padding-left-60 padding-right-60"
            @click="saveProduct"
          >
            <span>{{ trans('global.save') }}</span>
          </b-button>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { minLength, required } from 'vuelidate/lib/validators'

  import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
  import {
    Heading,
    Blockquote,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline
  } from 'tiptap-extensions'

  import Icon from '../../../../../Core/WysiwygIcons'

  export class CustomLink extends Link {
    get schema () {
      return {
        attrs: {
          href: {
            default: null
          },
          target: {
            default: null
          }
        },
        inclusive: false,
        parseDOM: [
          {
            tag: 'a[href]',
            getAttrs: dom => ({
              href: dom.getAttribute('href'),
              target: dom.getAttribute('target')
            })
          }
        ],
        toDOM: node => ['a', {
          ...node.attrs,
          target: '__blank',
          rel: 'noopener noreferrer nofollow'
        }, 0]
      }
    }
  }

  const fb = require('../../../../../../firebaseConfig')
  const varyConfig = require('../../../../../../varyConfig')

  export default {
    name: 'ModalAddRoomNewProduct',
    components: {
      EditorContent,
      EditorMenuBar,
      EditorMenuBubble,
      Icon
    },
    mixins: [validationMixin],
    props: {
      project: {
        type: Object,
        required: true
      },
      prospect: {
        type: Object,
        required: true
      },
      roomkey: {
        type: Number,
        required: true
      },
      roomcategory: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        linkUrl: null,
        linkMenuIsActive: false,
        editor: null,
        productDescHtml: '',
        submitStatus: 0, // 0 = idle, 1 = processing
        uploadStatus: 0, // 0 = idle, 1 = processing
        progress: 0,
        productForm: {
          name: '',
          shorttxt: '',
          longtxt: '',
          image: null
        },
        roomCategories: varyConfig.roomCategories
      }
    },
    validations () {
      return {
        productForm: {
          name: {
            required,
            minlength: minLength(2)
          }
        }
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile
      }),
      formErrors () {
        return {
          name: this.$v.productForm.name.$dirty && this.submitStatus === 1 ? !this.$v.productForm.name.$error : null
        }
      },
      productImageStyle () {
        const imgUrl = this.productForm.image !== null && this.productForm.image.name !== undefined ? URL.createObjectURL(this.productForm.image) : false
        return imgUrl ? 'background-image: url("' + imgUrl + '")' : null
      }
    },
    mounted () {
      this.$root.$on('bv::modal::hide', () => {
        this.submitStatus = 0
      })
      this.initializeEditor()
    },
    beforeDestroy () {
      this.editor.destroy()
    },
    methods: {
      saveProduct () {
        const self = this
        console.log('saveProduct() running:')
        this.submitStatus = 1
        this.submitbtnwidth = this.$refs.submitbtn ? this.$refs.submitbtn.clientWidth : 'auto'

        // Errorhandling
        this.$v.productForm.$touch()
        if (this.$v.productForm.$anyError) {
          this.submitStatus = 0
          return
        }

        const slug = this.createSlug(this.productForm.name, 'products/slugHelper')
        const varycode = this.doPaddy(this.$store.state.configs.varyCode)

        let productId = false
        let nextProductId = false

        /*
          Save new product and add it to the prospect room:
          1. Create a new varycode for the product in the varycode database
          2. Save the product to the products database
          3. Update the varycode with the productId
          4. Save the product to the room in the prospect database
          5. Upload image and save the info to the product in the product database
          6. Update the product in the room in the prospect with the newly added imagedata
          7. Done!
         */

        // Create new varycode - i the promise-chain from hell: TODO: Fix!
        console.log('1. Create new VaryCode')
        fb.varycodesCollection.doc(varycode).set({
          createdOn: new Date(),
          varycode,
          userId: self.userProfile.uid,
          type: 4 // 1 = project, 2 = unit, 3 = prospect, 4 = product
        }).then(() => {
          // Save the product to the product database
          console.log('2. Save the product to the database')
          return fb.productsCollection.add({
            createdOn: new Date(),
            varycode,
            name: self.productForm.name,
            slug,
            shorttxt: self.productDescHtml,
            longtxt: self.productForm.longtxt,
            userId: self.userProfile.uid,
            deleted: false
          })
        }).then((res) => {
          // Update Varycode entry with the newly created product ID
          console.log('3. Update the varycode entry with the typeId')
          if (res.id !== undefined && res.id !== '') {
            productId = res.id
            fb.varycodesCollection.doc(varycode).set({
              typeId: res.id
            }, { merge: true })

            return res // Sending prev res to next then to save the parentId
          } else {
            return Promise.reject(new Error('Unable to get the new productId after save'))
          }
        }).then((res) => {
          // Get the number of the next productId in the room, and save the product to the room
          console.log('4. Save the product to the room')

          // Check if the last productID is a valid number, else replace it with the new valid product
          // If a bug occurs, the last product might become "undefined", and this will clean that up (messy, but works for now)
          if (self.prospect.rooms[self.roomkey].products !== undefined && Object.keys(self.prospect.rooms[self.roomkey].products).length > 0) {
            if (!isNaN(parseInt(Object.keys(self.prospect.rooms[self.roomkey].products).pop()))) {
              nextProductId = parseInt(Object.keys(self.prospect.rooms[self.roomkey].products).pop()) + 1
            } else { // Fetch the previous product ID and delete the invalid one
              nextProductId = parseInt(Object.keys(self.prospect.rooms[self.roomkey].products)[Object.keys(self.prospect.rooms[self.roomkey].products).length - 2]) + 1
              fb.prospectsCollection.doc(self.prospect._id).update({
                [`rooms.${self.roomkey}.products.${Object.keys(self.prospect.rooms[self.roomkey].products).pop().toString()}`]: fb.firebase.firestore.FieldValue.delete()
              })
            }
          } else {
            nextProductId = 0
          }

          console.log('NextProductId: ' + nextProductId)

          // nextProductId = self.prospect.rooms[self.roomkey].products !== undefined ? parseInt(Object.keys(self.prospect.rooms[self.roomkey].products).pop()) + 1 : 0

          if (!isNaN(nextProductId)) {
            return fb.prospectsCollection.doc(self.prospect._id).update({
              [`rooms.${self.roomkey}.products.${nextProductId}`]: {
                createdOn: new Date(),
                varycode,
                parentId: res.id,
                name: self.productForm.name,
                slug,
                shorttxt: self.productDescHtml,
                longtxt: self.productForm.longtxt,
                userId: self.userProfile.uid,
                deleted: false
              }
            })
          } else {
            return Promise.reject(new Error('Unable to get the nextProductId'))
          }
        }).then(() => {
          // If image, save it to the server
          console.log('5. If image, save it to the server')
          self.uploadStatus = 1
          if (self.productForm.image.name !== undefined) {
            let randomTxt = ''
            const length = 16
            const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

            for (let i = 0; i < length; i++) {
              randomTxt += possible.charAt(Math.floor(Math.random() * possible.length))
            }

            const storageRef = fb.storage.ref()
            const metadata = {
              contentType: self.productForm.image.type
            }

            const newFilename = slug + '_' + randomTxt + '.' + self.productForm.image.name.split('.').pop()
            const fileOrgName = self.productForm.image.name
            const fulldate = new Date()
            const imageTargetPath = 'products/' + fulldate.getFullYear() + '/' + varycode

            const uploadTask = storageRef.child(imageTargetPath + '/' + newFilename).put(this.productForm.image, metadata) // Upload the file

            return new Promise((resolve, reject) => {
              uploadTask.on('state_changed', function (snapshot) {
                self.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

                switch (snapshot.state) {
                  case 'paused':
                    console.log('Upload is paused')
                    break
                  case 'running':
                    console.log('Upload is running')
                }
              }, function (error) {
                let err = ''
                switch (error.code) {
                  case 'storage/unauthorized':
                    err = 'Storage autorization required, access denied...'
                    break
                  case 'storage/canceled':
                    err = 'The upload was cancelled'
                    break
                  case 'storage/unknown':
                    err = 'An unknown error occurred during upload'
                    break
                }
                reject(new Error(err))
              }, function () {
                self.uploadStatus = 0
                resolve({
                  newFilename,
                  fileOrgName,
                  imageTargetPath,
                  metadata
                })
              })
            })
          } else {
            Promise.resolve() // Image is not required, so if it doesn't exist, continue.
          }
        }).then((res) => {
          // If a image was uploaded, save the data to the product both in the actual product
          // database, and to the prospect -> room -> product
          console.log('6. Save imageinfo to the product?')
          if (res !== undefined && res.newFilename !== undefined) {
            console.log('...Saving info to products db')
            return fb.productsCollection.doc(productId).update({
              'image.0': { // Will always be 0 because this product has just been created
                createdOn: new Date(),
                file: res.newFilename,
                orgFile: res.fileOrgName,
                path: res.imageTargetPath,
                metadata: res.metadata,
                userId: self.$store.state.auth.userProfile.uid,
                deleted: false
              }
            }).then(() => {
              // console.log('...Copying the image to the prospect folder: DATA:')
              console.log('Saving image URL to Product')

              // const copyImage = fb.func.httpsCallable('copyImage')
              //
              // return copyImage({ filename: res.newFilename, path: res.imageTargetPath, projectVaryCode: self.project.varycode, prospectVaryCode: self.prospect.varycode, productVaryCode: varycode, roomId: self.roomkey, roomCategory: self.roomcategory }).then((cloudFunctionResult) => {
              //   console.log('...Image was copied from product to prospect product!')
              //   return cloudFunctionResult
              // }).then((cloudFunctionResult) => {
              //   console.log('...Saving info to prospects->room->product db')
              //   return fb.prospectsCollection.doc(self.prospect._id).update({
              //     [`rooms.${self.roomkey}.products.${nextProductId}.image.0`]: { // will always be 0
              //       createdOn: new Date(),
              //       file: res.newFilename,
              //       orgFile: res.fileOrgName,
              //       path: cloudFunctionResult.data.path,
              //       metadata: res.metadata,
              //       userId: self.$store.state.auth.userProfile.uid,
              //       deleted: false
              //     }
              //   })
              // })

              // Save ORIGINAL product data to the prospect product as well - changes in progress...
              return fb.prospectsCollection.doc(self.prospect._id).update({
                [`rooms.${self.roomkey}.products.${nextProductId}.image.0`]: {
                  createdOn: new Date(),
                  file: res.newFilename,
                  orgFile: res.fileOrgName,
                  path: res.imageTargetPath,
                  metadata: res.metadata,
                  userId: self.$store.state.auth.userProfile.uid,
                  deleted: false
                }
              })
            })
          } else {
            Promise.resolve() // If no imageinfo, continue
          }
        }).then(() => {
          console.log('Everything was updated fine')

          this.$bvToast.toast(this.trans('prospects.designer.form_add_room_new_product.product_added_successfully'), {
            title: this.trans('global.success'),
            variant: 'success',
            solid: true
          })

          self.submitStatus = 0

          self.productForm.name = ''
          self.productForm.shorttxt = ''
          self.productDescHtml = ''
          self.productForm.longtxt = ''
          self.productForm.image = null

          this.$root.$emit('bv::hide::modal', 'add-room-new-product-modal')
        }).catch((err) => {
          console.log('Something went wrong')
          console.log(err)
        })
      },
      initializeEditor () {
        const self = this
        self.editor = new Editor({
          extensions: [
            new Heading({ levels: [1, 2, 3] }),
            new Blockquote(),
            new OrderedList(),
            new BulletList(),
            new ListItem(),
            new TodoItem(),
            new TodoList(),
            new Bold(),
            new Italic(),
            new CustomLink({ openOnClick: false }),
            new Strike(),
            new Underline()
          ],
          content: this.productForm.shorttxt,
          onUpdate: ({ getHTML }) => {
            this.productDescHtml = getHTML()
          }
        })
      },
      showLinkMenu (attrs) {
        this.linkUrl = attrs.href
        this.linkMenuIsActive = true
        this.$nextTick(() => {
          this.$refs.linkInput.focus()
        })
      },
      hideLinkMenu () {
        this.linkUrl = null
        this.linkMenuIsActive = false
      },
      setLinkUrl (command, url) {
        command({ href: url })
        this.hideLinkMenu()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .modal-header-wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 26px;

    h4 {
      margin-bottom: 0;
    }
  }

  #product_image_group {
    width: 300px;
  }

  .vary-image-container {
    display: flex;
    background-color: #fcfcfc;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #DFE4EB;
    border-radius: 4px;
    width: 300px;
    height: 300px;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &.noimg {
      padding-top: 300px / 300px * 100%;
    }

    .text {
      margin-top: -(300px / 300px * 100%);
      color: #B5BCC6;
      text-align: center;

      i {
        margin-bottom: 20px;
      }

      span {
        display: block;
      }
    }
  }

  .vary-image-progress {
    transition: opacity .5s;
    width: 100%;
    margin-bottom: 10px;
  }

  .menububble {
    position: absolute;
    display: -webkit-box;
    display: flex;
    z-index: 20;
    background: #000;
    border-radius: 5px;
    padding: .3rem;
    margin-bottom: .5rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .2s,visibility .2s;
    transition: opacity .2s,visibility .2s
  }

  .menububble.is-active {
    opacity: 1;
    visibility: visible
  }

  .menububble__button {
    display: -webkit-inline-box;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #fff;
    padding: .2rem .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    cursor: pointer
  }

  .menububble__button:last-child {
    margin-right: 0
  }

  .menububble__button:hover {
    background-color: hsla(0,0%,100%,.1)
  }

  .menububble__button.is-active {
    background-color: hsla(0,0%,100%,.2)
  }

  .menububble__form {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center
  }
</style>
