<template>
  <div>
    <b-container>
      <b-row>
        <b-col>
          <Heading type="h1" :title="trans('global.homeconfigurator')" />
        </b-col>
      </b-row>
    </b-container>
    <div class="roomselector">
      <b-container>
        <b-row>
          <b-col class="position-relative">
            <b-btn
              id="add_room_btn"
              v-b-modal.add-room-modal
              variant="success"
              class="text-white"
            >
              <i class="far fa-plus mr-2"></i> Legg til rom
            </b-btn>
            <b-tabs v-model="tabRoomIndex" content-class="mt-3" class="roomselector-menu">
              <b-tab
                v-for="room in rooms"
                :key="`room_${room.id}`"
                :title="`${room.name}`"
                @click="toggleRoom(room.id)"
              >
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <b-row>
        <b-col v-for="room in rooms" :id="room.slug" :key="`room_content_${room.id}`" sm="12">
          <div v-if="currentRoomKey === parseInt(room.id)">
            <b-form id="roomforms">
              <b-row>
                <b-col sm="12">
                  <b-row>
                    <b-col>
                      <h2>{{ trans('global.homeconfiguratorimage') }}</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group :id="`room_image_group_${room.id}`" :label="trans('prospects.landingpage.image')" :label-for="`room_image_${room.id}`">
                        <div :class="(!roomImage[room.id])? 'noimg' : ''" class="room-image-container" :style="roomImage[room.id]">
                          <div v-if="!roomImage[room.id]" class="room-image-container-content">
                            <div class="room-image-container-content-flexbox">
                              <div class="room-image-container-content-flexbox-content">
                                <i class="fas fa-file-image" style="font-size: 40px;"></i>
                                <span>{{ trans('prospects.landingpage.image_description') }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="uploadStatus === 1" class="room-image-progress">
                          <b-progress :value="Math.round(progress)" variant="primary" striped :animated="true" show-progress></b-progress>
                        </div>
                        <b-form-file
                          :id="`room_image_${room.id}`"
                          v-model="formData[room.id].image"
                          :placeholder="trans('projects.image_placeholder')"
                          class="margin-top-16"
                          accept="image/jpeg, image/png"
                          @change="updateImage"
                        >
                        </b-form-file>
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <b-form-group :id="`room_render_group_${room.id}`" label="Visningsbilde på indeks siden" :label-for="`room_render_${room.id}`">
                        <div :class="(!roomRender[room.id])? 'noimg' : ''" class="room-image-container" :style="roomRender[room.id]">
                          <div v-if="!roomRender[room.id]" class="room-image-container-content">
                            <div class="room-image-container-content-flexbox">
                              <div class="room-image-container-content-flexbox-content">
                                <i class="fas fa-file-image" style="font-size: 40px;"></i>
                                <span>Last opp bildet som skal vises på indeks siden</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div v-if="uploadRenderStatus === 1" class="room-image-progress">
                          <b-progress :value="Math.round(renderProgress)" variant="primary" striped :animated="true" show-progress></b-progress>
                        </div>
                        <b-form-file
                          :id="`room_render_${room.id}`"
                          v-model="formData[room.id].image"
                          :placeholder="trans('projects.image_placeholder')"
                          class="margin-top-16"
                          accept="image/jpeg, image/png"
                          @change="updateRender"
                        >
                        </b-form-file>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="margin-top-30">
                <b-col>
                  <b-row class="justify-content-between">
                    <b-col class="col-auto">
                      <h2 v-if="roomProductsCount > 0">
                        {{ trans('global.products') }} ({{ roomProductsCount }})
                      </h2>
                      <h2 v-else>
                        {{ trans('global.products') }}
                      </h2>
                    </b-col>
                    <b-col class="col-auto text-right">
                      <b-dropdown variant="primary" class="d-inline-block margin-right-16" :text="trans('global.administrate')">
                        <b-dropdown-item v-b-modal.add-room-category-modal>
                          {{ trans('global.folders') }}
                        </b-dropdown-item>
                        <b-dropdown-item v-b-modal.manage-rooms-modal>
                          {{ trans('global.rooms') }}
                        </b-dropdown-item>
                        <b-dropdown-item v-b-modal.manage-room-products>
                          {{ trans('global.products') }}
                        </b-dropdown-item>
                      </b-dropdown>
                      <b-button v-b-modal.add-existing-prodict-to-room-modal variant="primary" class="margin-right-16">
                        {{ trans('prospects.designer.form_add_room_product.add_existing') }}
                      </b-button>
                      <b-button v-b-modal.add-room-new-product-modal variant="primary">
                        {{ trans('prospects.designer.form_add_room_product.add_new') }}
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row v-if="Object.keys(roomProducts[currentRoomKey]).length > 0">
                    <b-col>
                      <b-table
                        id="designerproducts"
                        primary-key="productkey"
                        striped
                        hover
                        :items="items"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :no-local-sorting="true"
                        @sort-changed="sortChanged"
                      >
                        <template v-slot:cell(image)="row">
                          <div class="render-wrapper" :style="row.item.image ? 'background-image: url(' + row.item.image + '?fit=crop&w=100);' : '' ">
                            <!--<div class="radial-progress-wrp">-->
                            <!--  <RadialProgressBar-->
                            <!--    v-if="uploadingProductImage.includes(row.item.productkey + '_image')"-->
                            <!--    :diameter="50"-->
                            <!--    :completed-steps="progresses[row.item.productkey]"-->
                            <!--    :total-steps="100"-->
                            <!--    :stroke-width="4"-->
                            <!--    inner-stroke-color="#eaeaea"-->
                            <!--    :animate-speed="200"-->
                            <!--  >-->
                            <!--    <p>{{ Math.round(progresses[row.item.productkey]) }}</p>-->
                            <!--  </RadialProgressBar>-->
                            <!--</div>-->

                            <!--<b-form class="w-100 h-100" @submit.prevent>-->
                            <!--  <label class="select-label">-->
                            <!--    <span v-if="row.item.image !== false">-->
                            <!--      <i v-if="!uploadingProductImage.includes(row.item.productkey + '_image')" class="fal fa-retweet toggleme"></i>-->
                            <!--    </span>-->
                            <!--    <span v-else class="text-warning">-->
                            <!--      <i v-if="!uploadingProductImage.includes(row.item.productkey + '_image')" class="fal fa-exclamation-triangle"></i>-->
                            <!--      <p v-if="!uploadingProductImage.includes(row.item.productkey + '_image')">{{ trans('prospects.designer.no_image') }}</p>-->
                            <!--    </span>-->
                            <!--    <input type="file" @change="uploadProductImage($event, row.item.productkey, 'image')" />-->
                            <!--  </label>-->
                            <!--</b-form>-->
                          </div>
                        </template>

                        <template v-slot:cell(name)="row">
                          <b-col class="font-size-large">
                            <p :id="'tooltip-target-productinfo-' + row.item.productkey">
                              {{ row.item.name }}
                            </p>
                            <b-tooltip :target="'tooltip-target-productinfo-' + row.item.productkey" triggers="hover">
                              <div v-if="row.item.productinfo.short !== '' || row.item.productinfo.long !== ''">
                                <p v-if="row.item.productinfo.short !== ''" class="font-weight-bold text-left my-0 text-success">
                                  {{ trans('prospects.designer.short_txt') }}:
                                </p>
                                <p v-if="row.item.productinfo.short !== ''" class="text-left font-weight-light">
                                  {{ row.item.productinfo.short | truncate(50) }}
                                </p>
                                <p v-if="row.item.productinfo.long !== ''" class="font-weight-bold text-left my-0 text-success">
                                  {{ trans('prospects.designer.long_txt') }}:
                                </p>
                                <p v-if="row.item.productinfo.long !== ''" class="text-left font-weight-light">
                                  {{ row.item.productinfo.long | truncate(80) }}
                                </p>
                              </div>
                              <div v-else>
                                <p>{{ trans('prospects.designer.no_txt') }}</p>
                              </div>
                            </b-tooltip>
                          </b-col>
                        </template>

                        <template v-slot:cell(price)="row">
                          <b-form @submit.prevent>
                            <b-form-input :value="row.item.price" class="form-control-lg" type="text" trim @blur="updateProductValue(row.item.productkey, 'price', $event.target.value)"></b-form-input>
                          </b-form>
                        </template>

                        <template v-slot:cell(amount)="row">
                          <b-form @submit.prevent>
                            <b-form-input :value="row.item.amount" class="form-control-lg" type="text" trim @blur="updateProductValue(row.item.productkey, 'amount', $event.target.value)"></b-form-input>
                          </b-form>
                        </template>

                        <template v-slot:cell(designation)="row">
                          <b-form @submit.prevent>
                            <b-form-select v-model="row.item.designation" size="lg" class="form-control-lg" @change="updateProductValue(row.item.productkey, 'designation', row.item.designation, 0)">
                              <option v-for="(des, key) in designationTypes" :key="`des_${key}`" :value="des.value">
                                {{ des.title }}
                              </option>
                            </b-form-select>
                          </b-form>
                        </template>

                        <template v-slot:cell(showinsummary)="row">
                          <b-form @submit.prevent>
                            <b-form-checkbox
                              :id="`summary_chkbox_${row.item.productkey}`"
                              v-model="row.item.showinsummary"
                              :disabled="disableCheckboxes"
                              @change="updateProductValue(row.item.productkey, 'showinsummary', row.item.showinsummary ? 1 : 0, 0)"
                            ></b-form-checkbox>
                          </b-form>
                        </template>

                        <template v-slot:cell(layer)="row">
                          <b-form @submit.prevent>
                            <b-form-input :value="row.item.layer" class="form-control-lg" type="text" trim @keyup="updateProductValue(row.item.productkey, 'layer', $event.target.value)"></b-form-input>
                          </b-form>
                        </template>

                        <template v-slot:cell(styletype)="row">
                          <b-form @submit.prevent>
                            <b-form-select v-if="prospectStyletypes" v-model="row.item.styletype" size="lg" class="form-control-lg" @change="updateProductValue(row.item.productkey, 'styletype', row.item.styletype, 0)">
                              <option :value="null">
                                {{ trans('global.none') }}
                              </option>
                              <option :value="-1">
                                {{ trans('global.standard') }}
                              </option>
                              <option v-for="style in prospect.styleTypes" :key="`styletype_${style.id}`" :value="style.id">
                                {{ style.name }}
                              </option>
                            </b-form-select>
                            <b-form-select v-else v-model="row.item.styletype" size="lg" class="form-control-lg" @change="updateProductValue(row.item.productkey, 'styletype', row.item.styletype, 0)">
                              <option :value="null">
                                {{ trans('global.none') }}
                              </option>
                              <option :value="-1">
                                {{ trans('global.standard') }}
                              </option>
                            </b-form-select>
                          </b-form>
                        </template>

                        <template v-slot:cell(category)="row">
                          <b-form v-if="roomInventoryCategories" @submit.prevent>
                            <b-form-select v-model="row.item.category" size="lg" class="form-control-lg" @change="updateProductValue(row.item.productkey, 'category', row.item.category, 0)">
                              <option :value="null">
                                {{ trans('prospects.designer.choose_folder') }}
                              </option>
                              <option v-for="cat in roomInventoryCategories" :key="`category_${cat.key}`" :value="cat.key">
                                {{ cat.title }}
                              </option>
                            </b-form-select>
                          </b-form>
                          <p v-else>
                            {{ trans('prospects.designer.no_folder') }}
                          </p>
                        </template>

                        <template v-slot:cell(render)="row">
                          <div class="render-wrapper" :style="row.item.render ? 'background-image: url(' + row.item.render + '?fit=crop&w=100);' : '' ">
                            <div class="radial-progress-wrp">
                              <RadialProgressBar
                                v-if="uploadingProductImage.includes(row.item.productkey + '_render')"
                                :diameter="50"
                                :completed-steps="progresses[row.item.productkey]"
                                :total-steps="100"
                                :stroke-width="4"
                                inner-stroke-color="#eaeaea"
                                :animate-speed="200"
                              >
                                <p>{{ Math.round(progresses[row.item.productkey]) }}</p>
                              </RadialProgressBar>
                            </div>

                            <b-form class="w-100 h-100" @submit.prevent>
                              <label class="select-label">
                                <span v-if="row.item.render !== false">
                                  <i v-if="!uploadingProductImage.includes(row.item.productkey + '_render')" class="fal fa-retweet toggleme"></i>
                                </span>
                                <span v-else class="text-warning">
                                  <i v-if="!uploadingProductImage.includes(row.item.productkey + '_render')" class="fal fa-exclamation-triangle"></i>
                                  <p v-if="!uploadingProductImage.includes(row.item.productkey + '_render')">{{ trans('prospects.designer.no_render') }}</p>
                                </span>
                                <input type="file" @change="uploadProductImage($event, row.item.productkey, 'render')" />
                              </label>
                            </b-form>
                          </div>
                        </template>

                        <template v-slot:cell(overlay)="row">
                          <div class="render-wrapper overlay-wrapper" :style="row.item.overlay ? 'background-image: url(' + row.item.overlay + '?fit=crop&w=50);' : '' ">
                            <div class="radial-progress-wrp">
                              <RadialProgressBar
                                v-if="uploadingProductImage.includes(row.item.productkey)"
                                :diameter="20"
                                :completed-steps="progresses[row.item.productkey]"
                                :total-steps="100"
                                :stroke-width="2"
                                inner-stroke-color="#eaeaea"
                                :animate-speed="200"
                              >
                                <p>{{ Math.round(progresses[row.item.productkey]) }}</p>
                              </RadialProgressBar>
                            </div>

                            <b-form class="w-100 h-100" @submit.prevent>
                              <label class="select-label">
                                <span v-if="row.item.overlay !== false">
                                  <i v-if="!uploadingProductImage.includes(row.item.productkey + '_overlay')" class="fal fa-retweet toggleme"></i>
                                </span>
                                <span v-else class="text-muted">
                                  <p v-if="!uploadingProductImage.includes(row.item.productkey + '_overlay')">{{ trans('prospects.designer.no_overlay') }}</p>
                                </span>
                                <input type="file" @change="uploadProductImage($event, row.item.productkey, 'overlay')" />
                              </label>
                            </b-form>
                          </div>
                          <div>
                            <b-form @submit.prevent>
                              <b-form-select v-model="row.item.blendMode" size="sm" class="form-control-lg" @change="updateProductValue(row.item.productkey, 'blendMode', row.item.blendMode, 0)">
                                <option :value="null">
                                  Velg mode
                                </option>
                                <option v-for="(mode, idx) in blendModes" :key="`mode_${idx}`" :value="mode.key">
                                  {{ mode.title }}
                                </option>
                              </b-form-select>
                            </b-form>
                          </div>
                        </template>

                        <template v-slot:cell(linked)="row">
                          <b-link class="linked-wrapper" :class="row.item.linked ? 'linked' : 'unlinked'" @click="openLinkProductModal(row.item.productkey)">
                            <div v-if="row.item.linked">
                              <i class="far fa-link"></i>
                              <p>{{ trans('prospects.designer.linked') }}</p>
                            </div>
                            <div v-else>
                              <i class="far fa-unlink"></i>
                              <p>{{ trans('prospects.designer.not_linked') }}</p>
                            </div>
                          </b-link>
                        </template>

                        <template v-slot:cell(actions)="row">
                          <b-dropdown size="md" variant="primary" no-caret right>
                            <template v-slot:button-content>
                              <i class="fas fa-caret-down"></i>
                            </template>
                            <b-dropdown-item :to="'/products/edit/' + row.item.productvarycode">
                              {{ trans('global.edit') }}
                            </b-dropdown-item>
                            <ModalEditProspectProduct
                              :prospect="prospect"
                              :roomkey="currentRoomKey"
                              :productkey="row.item.productkey"
                            />
                            <b-dropdown-item @click="deleteProduct(row.item.productkey)">
                              {{ trans('global.delete') }}
                            </b-dropdown-item>
                          </b-dropdown>
                        </template>
                      </b-table>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col md="6" offset-md="3">
                      <div class="alert alert-warning margin-top-60 margin-bottom-60">
                        <i class="fas fa-exclamation-triangle padding-right-6"></i> {{ trans('products.no_products') }}
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <ModalManageRoomCategories
      :project="project"
      :prospect="prospect"
      :roomkey="currentRoomKey"
      :roomproducts="roomProducts"
    />
    <ModalAddExistingProductToRoom
      :project="project"
      :prospect="prospect"
      :roomkey="currentRoomKey"
      :roomcategory="currentRoomCategory"
    />
    <ModalAddRoomNewProduct
      :project="project"
      :prospect="prospect"
      :roomkey="currentRoomKey"
      :roomcategory="currentRoomCategory"
    />
    <ModalLinkProducts
      :prospect="prospect"
      :roomkey="currentRoomKey"
      :productkey="productkey"
    />
    <ModalManageRoomProducts
      :prospect="prospect"
      :roomkey="currentRoomKey"
      :roomproducts="roomProducts"
    />
    <ModalManageRooms
      :current-room-key="currentRoomKey"
      :prospect="prospect"
    />
    <ModalAddRoom
      :prospect="prospect"
    />
  </div>
</template>

<script>
  import Vue from 'vue'
  import objectPath from 'object-path'

  import Heading from '../../../../Core/Heading'
  import RadialProgressBar from '../../../../Core/RadialProgressBar'
  import ModalLinkProducts from './Modals/RoomLinkProducts'
  import ModalAddExistingProductToRoom from './Modals/AddExistingProductToRoom'
  import ModalManageRoomCategories from './Modals/ManageRoomCategories'
  import ModalAddRoomNewProduct from './Modals/AddNewProductToRoom'
  import ModalEditProspectProduct from './Modals/EditProspectProduct'
  import ModalManageRoomProducts from './Modals/ManageRoomProducts'
  import ModalManageRooms from './Modals/ManageRooms'
  import ModalAddRoom from './Modals/AddRoom'

  const fb = require('../../../../../firebaseConfig')
  const varyConfig = require('../../../../../varyConfig')

  export default {
    name: 'ProjectSalesProspectDesigner',
    components: {
      ModalLinkProducts,
      Heading,
      ModalManageRoomCategories,
      ModalAddExistingProductToRoom,
      ModalAddRoomNewProduct,
      RadialProgressBar,
      ModalEditProspectProduct,
      ModalManageRoomProducts,
      ModalManageRooms,
      ModalAddRoom
    },
    props: {
      prospect: {
        type: Object,
        required: true
      },
      project: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        uploadStatus: 0, // 0 = idle, 1 = progress, 2 = complete
        uploadRenderStatus: 0, // 0 = idle, 1 = progress, 2 = complete
        uploadingProductImage: [], // product images beeing uploaded for individual loading anims
        currentRoomKey: 0,
        tabRoomIndex: 0,
        progress: 0,
        progresses: [],
        renderProgress: 0,
        roomCategories: varyConfig.roomCategories,
        designationTypes: varyConfig.designations,
        timer: [],
        productkey: '',
        sortBy: 'name',
        sortDesc: false,
        disableCheckboxes: false,
        blendModes: [
          { key: 'color', title: 'color' },
          { key: 'color-burn', title: 'burn' },
          { key: 'color-dodge', title: 'dodge' },
          { key: 'darken', title: 'darken' },
          { key: 'difference', title: 'difference' },
          { key: 'exclusion', title: 'exclusion' },
          { key: 'hard-light', title: 'hardlight' },
          { key: 'hue', title: 'hue' },
          { key: 'lighten', title: 'lighten' },
          { key: 'luminosity', title: 'luminosity' },
          { key: 'multiply', title: 'multiply' },
          { key: 'overlay', title: 'overlay' },
          { key: 'saturation', title: 'saturation' },
          { key: 'screen', title: 'screen' },
          { key: 'soft-light', title: 'softlight' }
        ]
      }
    },
    computed: {
      /*
       * rooms:
       * - Copies all data from the prospect.rooms prop
       * - Adds " 1", " 2" to the names if multiple rooms in same category exists
       * - Creates a slug based on the new generated (incremented) name
       * - Removes all rooms that's not included in this prospect (selected)
       * - Stores each room with the same key as in the db for consistency
       * - Note: all other computed props used this computed property as source
       */
      rooms () {
        const self = this
        const allRooms = this.prospect.rooms
        const cats = {}

        Object.keys(allRooms).map((key) => {
          let count = 0
          const name = self.trans(`global.${self.roomCategories[allRooms[key].category]}`)

          Object.keys(cats).map((k) => {
            count = (allRooms[key].category === cats[k]) ? count + 1 : count
          })

          cats[key] = allRooms[key].category

          if (allRooms[key].customname !== undefined && allRooms[key].customname !== '') {
            allRooms[key].name = allRooms[key].customname
          } else {
            allRooms[key].name = name + ((count > 0) ? ' ' + (count + 1) : '')
          }

          allRooms[key].slug = this.createSlug(allRooms[key].name) + '_' + key
        })

        const selectedRooms = {}
        Object.keys(allRooms).filter(roomid => allRooms[roomid].selected === 1).forEach((key) => {
          selectedRooms[key] = allRooms[key]
        })

        return selectedRooms
      },
      roomInventoryCategories () {
        const cats = {}
        if (this.rooms[this.currentRoomKey] !== undefined &&
          this.rooms[this.currentRoomKey].categories !== undefined &&
          Object.keys(this.rooms[this.currentRoomKey].categories).length > 0
        ) {
          Object.keys(this.rooms[this.currentRoomKey].categories).map((catKey) => {
            if (this.rooms[this.currentRoomKey].categories[catKey].deleted === undefined || this.rooms[this.currentRoomKey].categories[catKey].deleted === false) {
              const currentCat = this.rooms[this.currentRoomKey].categories[catKey]
              currentCat.key = catKey // Need this to keep the list sorting by :key optimal
              cats[catKey] = currentCat
            } // else - cat is deleted
          })
        } // else - room has no cats (^O.o^)

        return cats
      },
      prospectStyletypes () {
        return this.prospect.styleTypes !== undefined && Object.keys(this.prospect.styleTypes).length > 0
      },
      // fields (): The fields (heading) in the table
      fields () {
        return [
          { key: 'image', label: this.trans('global.image'), sortable: true },
          { key: 'name', label: this.trans('global.name'), sortable: true },
          { key: 'price', label: this.trans('global.price'), sortable: true },
          { key: 'designation', label: 'Benevnelse', sortable: false },
          { key: 'amount', label: 'Std.Ant', sortable: false },
          { key: 'showinsummary', label: 'Vis', sortable: false },
          { key: 'layer', label: this.trans('global.layer'), sortable: true },
          { key: 'styletype', label: this.trans('global.styletype'), sortable: true },
          { key: 'category', label: this.trans('global.folder'), sortable: true },
          { key: 'render', label: this.trans('global.confimg'), sortable: false },
          { key: 'overlay', lavbel: this.trans('global.overlay'), sortable: false },
          { key: 'linked', label: this.trans('global.linked'), sortable: true },
          { key: 'actions', label: 'Actions' }
        ]
      },
      // tableData (): The data for the items computed variable
      tableData () {
        const data = []
        const prod = this.roomProducts[this.currentRoomKey]
        Object.keys(prod).map((key) => {
          if (prod[key].deleted === undefined || prod[key].deleted !== true) {
            const product = prod[key]
            const currentProduct = {}
            let links = false

            // Product thumbnail data
            if (product.image !== undefined && typeof product.image === 'object' && product.image !== null) {
              const imagedataId = Object.keys(product.image).length > 1 ? Object.keys(product.image).pop() : 0
              currentProduct.imageurl = process.env.VUE_APP_IMAGE_CDN_URL + '/' + product.image[imagedataId].path + '/' + product.image[imagedataId].file
            } else if (typeof product.image === 'string') {
              currentProduct.imageurl = process.env.VUE_APP_IMAGE_CDN_URL + '/' + product.image
            } else {
              currentProduct.imageurl = false
            }

            // Render thumbnail data (check for varycode just to make sure the product exists)
            if (product.render !== undefined && product.varycode !== undefined) {
              const currentRenderImageId = Object.keys(product.render).length > 1 ? Object.keys(product.render).pop() : 0
              currentProduct.renderurl = process.env.VUE_APP_IMAGE_CDN_URL + '/' + product.render[currentRenderImageId].path + '/' + product.render[currentRenderImageId].file
            } else {
              currentProduct.renderurl = false
            }

            // Render overlay image
            if (product.render !== undefined && product.overlay !== undefined && product.varycode !== undefined) {
              const currentOverlayImageId = Object.keys(product.render).length > 1 ? Object.keys(product.render).pop() : 0
              const path = objectPath.get(product, `overlay.${currentOverlayImageId}.path`, false) // product.overlay[currentOverlayImageId].path
              if (path) {
                currentProduct.overlay = process.env.VUE_APP_IMAGE_CDN_URL + '/' + path + '/' + product.overlay[currentOverlayImageId].file
              } else {
                currentProduct.overlay = false
              }
            } else {
              currentProduct.overlay = false
            }

            // Render overlay image blend mode
            if (product.blendMode !== undefined && product.varycode !== undefined) {
              currentProduct.blendMode = product.blendMode
            } else {
              currentProduct.blendMode = null
            }

            // Render overlay image blend mode categories
            if (product.blendModeCategories !== undefined && product.varycode !== undefined) {
              console.log('product.blendModeCategories: ', product.blendModeCategories)
              const categories = product.blendModeCategories !== undefined ? product.blendModeCategories : []
              currentProduct.blendModeCategories = categories.toString()
            } else {
              currentProduct.blendModeCategories = null
            }

            // Linked to other products?
            if (product.links !== undefined && Object.keys(product.links).length > 0) {
              links = true
            }

            data.push({
              productvarycode: product.varycode,
              productkey: key,
              productinfo: { short: product.shorttxt, long: product.longtxt },
              image: currentProduct.imageurl,
              name: product.name,
              price: product.price !== undefined ? product.price : '0',
              showinsummary: product.showinsummary !== undefined ? product.showinsummary !== 0 : true,
              designation: product.designation !== undefined ? product.designation : null,
              amount: product.amount !== undefined ? product.amount : 0,
              layer: product.layer !== undefined ? parseInt(product.layer) : 0,
              styletype: product.styletype !== undefined ? product.styletype : null,
              category: product.category !== undefined ? product.category : null,
              render: currentProduct.renderurl,
              overlay: currentProduct.overlay,
              blendMode: currentProduct.blendMode,
              blendModeCategories: currentProduct.blendModeCategories,
              linked: links
            })
          } // else -> product has been soft-deleted
        })

        return data
      },
      // items (): The data for the items in the table-list
      items () {
        return this.sortTable(this.tableData, this.sortBy, this.sortDesc)
      },
      // formData (): Dynamic formdata for each room - builds the formData-structure
      formData () {
        const data = {}
        Object.keys(this.rooms).map((key) => {
          data[this.rooms[key].id] = {
            image: [] // Create empty initial slots for each room-image
          }
        })

        return data
      },
      // roomImage (): Fetches the latest images for each room and creates a background-style for it
      roomImage () {
        const roomImages = {}

        Object.keys(this.rooms).map((key) => {
          const imageData = this.rooms[key].image !== undefined && this.rooms[key].image !== false && Object.keys(this.rooms[key].image).length > 0 ? this.rooms[key].image[Object.keys(this.rooms[key].image).pop()] : false
          roomImages[this.rooms[key].id] = (imageData) ? 'background-image: url("' + process.env.VUE_APP_IMAGE_CDN_URL + '/' + imageData.path + '/' + imageData.file + '?fit=crop&w=600")' : false
        })

        return roomImages
      },
      // roomImage (): Fetches the latest images for each room and creates a background-style for it
      roomRender () {
        const roomRenders = {}

        Object.keys(this.rooms).map((key) => {
          const renderData = this.rooms[key].render !== undefined && this.rooms[key].render !== false && Object.keys(this.rooms[key].render).length > 0 ? this.rooms[key].render[Object.keys(this.rooms[key].render).pop()] : false
          roomRenders[this.rooms[key].id] = (renderData) ? 'background-image: url("' + process.env.VUE_APP_IMAGE_CDN_URL + '/' + renderData.path + '/' + renderData.file + '?fit=crop&w=600")' : false
        })

        return roomRenders
      },
      // roomProducts (): Fetches the products for each room (excluding the deleted ones)
      roomProducts () {
        const roomProducts = {}

        Object.keys(this.prospect.rooms).map((key) => {
          const products = {}
          if (this.prospect.rooms[key].products !== undefined && Object.keys(this.prospect.rooms[key].products).length > 0) {
            Object.keys(this.prospect.rooms[key].products).map((pKey) => {
              if (this.prospect.rooms[key].products[pKey].deleted === undefined || this.prospect.rooms[key].products[pKey].deleted !== true) {
                products[pKey] = this.prospect.rooms[key].products[pKey]
              } // else - product is deleted, skip it
            })
          } // else - no products in room
          roomProducts[key] = products
        })

        return roomProducts
      },
      roomProductsCount () {
        return Object.keys(this.roomProducts[this.currentRoomKey]).length
      },
      currentRoomCategory () {
        return this.roomCategories !== undefined &&
          this.rooms !== undefined &&
          this.currentRoomKey !== undefined &&
          this.roomCategories[this.rooms[this.currentRoomKey]] !== undefined &&
          this.roomCategories[this.rooms[this.currentRoomKey]].category !== undefined
          ? this.roomCategories[this.rooms[this.currentRoomKey]].category
          : ''
      }
    },
    mounted () {
      this.setCurrentRoomKey() // Set the first room as the current one - Todo: accept parameters
      console.log('Vary frontend link:')
      console.log('http://localhost:3000/eiendom/' + this.project.varycode + '/' + this.prospect.varycode)
      console.log('https://vary-frontend.firebaseapp.com/eiendom/' + this.project.varycode + '/' + this.prospect.varycode)
    },
    methods: {
      sortChanged (ctx) {
        this.sortBy = ctx.sortBy
        this.sortDesc = ctx.sortDesc
      },
      sortTable (items, sortBy, sortDesc) {
        const self = this
        return this.stableSort(items, function (a, b) {
          return self.customSortCompare(a, b, sortBy, sortDesc)
        })
      },
      // stableSort (): Borrowed from the Bootstrap-Vue repo for consistent and stable sort function
      stableSort (array, compareFn) {
        return array.map(function (a, index) {
          return [index, a]
        }).sort(function (a, b) {
          return this(a[1], b[1]) || a[0] - b[0]
        }.bind(compareFn)).map(function (e) {
          return e[1]
        })
      },
      // sortCompare (): Borrowed from the Bootstrap-Vue repo
      customSortCompare (aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
        const a = !sortDesc ? aRow[key] : bRow[key] // or use Lodash `_.get()`
        const b = !sortDesc ? bRow[key] : aRow[key]

        if (
          (typeof a === 'number' && typeof b === 'number') ||
          (a instanceof Date && b instanceof Date)
        ) {
          // If both compared fields are native numbers or both are native dates
          return a < b ? -1 : a > b ? 1 : 0
        } else {
          // Otherwise stringify the field data and use String.prototype.localeCompare
          return this.toString(a).localeCompare(this.toString(b), compareLocale, compareOptions)
        }
      },
      // toString (): Bootstrap toString helper value from the original Bootstrap GIT-repo
      toString (value) {
        if (value === null || typeof value === 'undefined') {
          return ''
        } else if (value instanceof Object) {
          return Object.keys(value)
            .sort()
            .map(key => toString(value[key]))
            .join(' ')
        } else {
          return String(value)
        }
      },
      // updateProductValue (): Updated the value of the current field, delayed if input...
      // WARNING: DO NOT use delayed timer on @change fields, because it will trigger a infinite loop
      updateProductValue (productKey, field, value, timer = 1000) {
        // check if value has changed (since onblur also uses updateProductValue())
        value = this.checkIfCorrectFormat(productKey, field, value)
        const productValue = objectPath.get(this.roomProducts, `${this.currentRoomKey}.${productKey}.${field}`)
        if (productValue === value) return // no change (on blur)

        const checkboxFields = [
          'showinsummary'
        ]

        if (checkboxFields.includes(field)) {
          this.disableCheckboxes = true
        }

        this.inputTimer(productKey, timer).then(() => {
          console.log('ProductKey: ', productKey)
          console.log('Field:', field)
          console.log('Value:', value)

          if (productKey !== undefined && field !== undefined && value !== undefined) {
            // Do some tests before updating
            Promise.all([
              this.checkIfAnotherProductIsStandardInThisCategory(productKey, field)
              // this.checkIfCorrectFormat(productKey, field, value)
            ]).then((res) => {
              console.log('Doing this:')
              const formattedValue = res[1] !== undefined ? res[1] : value

              fb.prospectsCollection.doc(this.prospect._id).update({
                [`rooms.${this.currentRoomKey}.products.${productKey}.${field}`]: formattedValue
              }).then(() => {
                this.updateLinkedProducts('styletype', formattedValue, productKey)
                this.$bvToast.toast(this.trans('prospects.designer.product_updated', { product: this.rooms[this.currentRoomKey].products[productKey].name }), {
                  title: this.trans('global.success'),
                  variant: 'success',
                  solid: true
                })
              }).catch((err) => {
                this.$bvToast.toast(this.trans('prospects.designer.product_updated', { product: this.rooms[this.currentRoomKey].products[productKey].name }), {
                  title: this.trans('global.error'),
                  variant: 'danger',
                  solid: true
                })
                console.log(err)
              }).finally(() => {
                if (checkboxFields.includes(field)) {
                  this.disableCheckboxes = false
                }
              })
            }).catch((err) => {
              console.log('Checks failed')
              console.log(err)

              if (checkboxFields.includes(field)) {
                this.disableCheckboxes = false
              }
            })
          } else {
            // Do errorhandling here
            console.log('UpdateValueField is missing required data')
          }
        })
      },
      // updateLinkedProductLinks()
      // Updates all linked products values so they all stay in sync
      updateLinkedProducts (category, value, productKey) {
        const self = this
        console.log('**updateLinkedProducts()**')
        if (category !== undefined && category !== null && value !== undefined && productKey !== undefined && productKey !== null) {
          console.log('Current product info:')
          console.log(this.roomProducts[this.currentRoomKey][productKey])

          if (this.roomProducts[this.currentRoomKey][productKey].links !== undefined && Object.keys(this.roomProducts[this.currentRoomKey][productKey].links).length > 0) {
            console.log('Updating linked rooms:')
            Object.keys(this.roomProducts[this.currentRoomKey][productKey].links).map((targetRoomKey) => {
              console.log('TargetRoom: ' + targetRoomKey)
              console.log('TargetRoomData')
              console.log(this.roomProducts[targetRoomKey])
              fb.prospectsCollection.doc(this.prospect._id).update({
                [`rooms.${targetRoomKey}.products.${self.roomProducts[self.currentRoomKey][productKey].links[targetRoomKey]}.${category}`]: value
              })
            })
          } else {
            console.log('No linked products')
          }
        } else {
          console.log('[ERROR] Missing category or value')
        }
      },
      // checkIfAnotherProductIsStandardInThisCategory (productId, field)
      // Checks if another product in this room and this category is already set as "Standard style"
      // Only one product is allowed to be the standard product pr category pr room
      checkIfAnotherProductIsStandardInThisCategory (productKey, field) {
        console.log('**checkIfAnotherProductIsStandardInThisCategory()**')
        const self = this
        if (field === 'styletype' || field === 'category') {
          return new Promise((resolve, reject) => {
            console.log(this.roomProducts[this.currentRoomKey])
            const products = this.roomProducts[this.currentRoomKey]
            const product = this.roomProducts[this.currentRoomKey][productKey]

            // Check if other products are in the same category and is set as "Standard" styletype
            Object.keys(products).map((pKey) => {
              if (products[pKey] !== undefined &&
                products[pKey].styletype !== undefined &&
                products[pKey].category !== undefined &&
                products[pKey].category !== null &&
                products[pKey].category === product.category &&
                products[pKey].styletype === -1
              ) {
                // Remove standard from the existing standard product
                fb.prospectsCollection.doc(self.prospect._id).update({
                  [`rooms.${self.currentRoomKey}.products.${pKey}.styletype`]: null
                }).then(() => {
                  console.log('-> Removed stylestype from product...')
                  console.log('-> Checking the linked products:')
                  // Oppdatere dette produktet sine linkede produkter med samme styletype
                  self.updateLinkedProducts('styletype', null, pKey)
                  resolve()
                }).catch((err) => {
                  console.log('[Error] There was an error while removing the existing standard-selection')
                  console.log(err)
                  reject(err)
                })
              } else {
                console.log('No other products in this category is set as standard')
                resolve()
              }
            })
          })
        } else {
          return Promise.resolve()
        }
      },
      checkIfCorrectFormat (productKey, field, value) {
        console.log('**checkIfCorrectFormat()**')
        console.log('FIELD:', field)

        // No need for promise yet...
        // return new Promise((resolve) => {
        if (field === 'price') {
          value = value.replace(',', '.')
          value = value.replace(/[^0-9.-]/g, '')
        } else if (field === 'layer') {
          value = Math.trunc(value)
        } else if (field === 'blendModeCategories') {
          value = value.split(',')
        }
        // resolve(value)
        // })

        return value
      },
      deleteProduct (productKey) {
        const self = this
        this.$bvModal.msgBoxConfirm(this.trans('prospects.designer.delete_product_are_you_sure', { product: this.roomProducts[this.currentRoomKey][productKey].name }), {
          title: this.trans('prospects.designer.delete_confirm'),
          size: 'sm',
          buttonSize: 'md',
          okVariant: 'danger',
          okTitle: this.trans('global.delete'),
          cancelTitle: this.trans('global.cancel')
        }).then((value) => {
          if (value !== false) {
            fb.prospectsCollection.doc(this.prospect._id).update({
              [`rooms.${this.currentRoomKey}.products.${productKey}.deleted`]: true
            }).then(() => {
              if (self.prospect.rooms[self.currentRoomKey].products[productKey].links !== undefined && Object.keys(self.prospect.rooms[self.currentRoomKey].products[productKey].links).length > 0) {
                this.removeLinkedProducts(productKey).then(() => {
                  this.$bvToast.toast(this.trans('prospects.designer.delete_confirmed'), {
                    title: this.trans('global.success'),
                    variant: 'success',
                    solid: true
                  })
                }).catch((err) => {
                  console.log('There was an error while removing the linked products')
                  console.log(err)
                })
              }
            }).catch((err) => {
              console.log('Error while deleting product')
              console.log(err)
            })
          } else {
            console.log('Save was aborted')
          }
        }).catch((err) => {
          console.log('Error')
          console.log(err)
        })
      },
      // Removes all linked products from the current product and this product from the linked
      // products - returns a promise
      removeLinkedProducts (productKey) {
        const self = this
        const links = {}

        Object.keys(this.prospect.rooms[this.currentRoomKey].products[productKey].links).map((roomKey) => {
          links[roomKey] = this.prospect.rooms[this.currentRoomKey].products[productKey].links[roomKey]
        })

        const updateQuery = {}
        // Remove links from the current product
        updateQuery[`rooms.${this.currentRoomKey}.products.${this.productkey}.links`] = fb.firebase.firestore.FieldValue.delete()

        // Remove links from the linked products
        Object.keys(links).map((roomKey) => {
          updateQuery[`rooms.${roomKey}.products.${links[roomKey]}.links.${this.currentRoomKey}`] = fb.firebase.firestore.FieldValue.delete()
        })

        return new Promise((resolve, reject) => {
          fb.prospectsCollection.doc(this.prospect._id).update(updateQuery).then(() => {
            self.currentRoom = self.prospect.rooms[self.currentRoomKey] // Update the currentRoom variable
            console.log('[1] The linked products was removed from this product')
            console.log('[2] The product was removed from the linked products')
            resolve()
          }).catch((err) => {
            console.log('[Error] There was a error while removing the linked products:')
            console.log(err)
            reject(err)
          })
        })
      },
      inputTimer (productId, timer) {
        return new Promise((resolve) => {
          if (this.timer[productId]) {
            clearTimeout(this.timer[productId])
            this.timer[productId] = null
          }

          this.timer[productId] = setTimeout(() => {
            resolve()
          }, timer)
        })
      },
      toggleRoom (roomKey) {
        this.setCurrentRoomKey(roomKey)
      },
      // setCurrentRoomKey (): Sets the value, or fetches the first room in the this.rooms object
      setCurrentRoomKey (roomKey = false) {
        this.currentRoomKey = parseInt((roomKey !== false) ? roomKey : (Object.keys(this.rooms).length > 0) ? Object.keys(this.rooms)[0] : 0)
      },
      // updateImage (e): Uploads the image and updates the data in the database
      updateImage (e) {
        // TODO: make the same function for updateImage (room image) and updateProductImage
        const imageData = e.target.files[0]

        if (imageData !== undefined && typeof imageData === 'object' && imageData.name !== undefined) {
          this.uploadStatus = 1
          const self = this
          const room = this.rooms[this.currentRoomKey]

          let randomTxt = ''
          const length = 16
          const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

          for (let i = 0; i < length; i++) {
            randomTxt += possible.charAt(Math.floor(Math.random() * possible.length))
          }

          const storageRef = fb.storage.ref()
          const metadata = {
            contentType: imageData.type
          }

          const newFilename = randomTxt + '.' + imageData.name.split('.').pop()
          const fileOrgName = imageData.name
          const imageTargetPath = 'projects/' + this.project.varycode + '/prospects/' + this.prospect.varycode + '/rooms/' + room.id + '_' + this.roomCategories[room.category]

          const uploadTask = storageRef.child(imageTargetPath + '/' + newFilename).put(imageData, metadata) // Upload the file

          uploadTask.on('state_changed', function (snapshot) {
            self.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
            }
          }, function (error) {
            switch (error.code) {
              case 'storage/unauthorized':
                console.log('Storage autorization required, access denied...')
                break
              case 'storage/canceled':
                console.log('The upload was cancelled')
                break
              case 'storage/unknown':
                console.log('An unknown error occurred during upload')
                break
            }
          }, function () {
            self.uploadStatus = 2

            const nextImageKey = room.image !== undefined ? parseInt(Object.keys(room.image).pop()) + 1 : 0

            fb.prospectsCollection.doc(self.prospect._id).update({
              [`rooms.${self.currentRoomKey}.image.${nextImageKey}`]: {
                createdOn: new Date(),
                file: newFilename,
                orgFile: fileOrgName,
                path: imageTargetPath,
                meta: metadata,
                userId: self.$store.state.auth.userProfile.uid,
                deleted: false
              }
            }).then(() => {
              self.$bvToast.toast(self.trans('prospects.designer.image_uploaded_successfully'), {
                title: self.trans('global.success'),
                variant: 'success',
                solid: true
              })
            }).catch((err) => {
              // Do errorhandling here
              console.log('Error while updating room image:')
              console.log(err)
            })
          })
        } else {
          // Do error-handling here
          console.log('Error while uploading the image...')
        }
      },
      // updateImage (e): Uploads the image and updates the data in the database
      updateRender (e) {
        // TODO: make the same function for updateImage (room image) and updateProductImage
        const imageData = e.target.files[0]

        if (imageData !== undefined && typeof imageData === 'object' && imageData.name !== undefined) {
          this.uploadRenderStatus = 1
          const self = this
          const room = this.rooms[this.currentRoomKey]

          let randomTxt = ''
          const length = 16
          const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

          for (let i = 0; i < length; i++) {
            randomTxt += possible.charAt(Math.floor(Math.random() * possible.length))
          }

          const storageRef = fb.storage.ref()
          const metadata = {
            contentType: imageData.type
          }

          const newFilename = randomTxt + '.' + imageData.name.split('.').pop()
          const fileOrgName = imageData.name
          const imageTargetPath = 'projects/' + this.project.varycode + '/prospects/' + this.prospect.varycode + '/rooms/' + room.id + '_' + this.roomCategories[room.category] + '/render'

          const uploadTask = storageRef.child(imageTargetPath + '/' + newFilename).put(imageData, metadata) // Upload the file

          uploadTask.on('state_changed', function (snapshot) {
            self.renderProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
            }
          }, function (error) {
            switch (error.code) {
              case 'storage/unauthorized':
                console.log('Storage autorization required, access denied...')
                break
              case 'storage/canceled':
                console.log('The upload was cancelled')
                break
              case 'storage/unknown':
                console.log('An unknown error occurred during upload')
                break
            }
          }, function () {
            self.uploadRenderStatus = 2

            const nextImageKey = room.render !== undefined ? parseInt(Object.keys(room.render).pop()) + 1 : 0

            fb.prospectsCollection.doc(self.prospect._id).update({
              [`rooms.${self.currentRoomKey}.render.${nextImageKey}`]: {
                createdOn: new Date(),
                file: newFilename,
                orgFile: fileOrgName,
                path: imageTargetPath,
                meta: metadata,
                userId: self.$store.state.auth.userProfile.uid,
                deleted: false
              }
            }).then(() => {
              self.$bvToast.toast(self.trans('prospects.designer.image_uploaded_successfully'), {
                title: self.trans('global.success'),
                variant: 'success',
                solid: true
              })
            }).catch((err) => {
              // Do errorhandling here
              console.log('Error while updating room image:')
              console.log(err)
            })
          })
        } else {
          // Do error-handling here
          console.log('Error while uploading the image...')
        }
      },
      // uploadProductImage (e, productObjectKey): Uploads and updates the product images
      // Type: 'render' or 'image' or 'overlay'
      uploadProductImage (e, productObjectKey, type) {
        // TODO: make the same function for updateImage (room image) and updateProductImage
        const imageData = e.target.files[0]

        if (imageData !== undefined && typeof imageData === 'object' && imageData.name !== undefined && type !== undefined) {
          this.uploadingProductImage.push(productObjectKey + '_' + type)
          const self = this
          const room = this.rooms[this.currentRoomKey]
          const product = room.products[productObjectKey]

          let randomTxt = ''
          const length = 16
          const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

          for (let i = 0; i < length; i++) {
            randomTxt += possible.charAt(Math.floor(Math.random() * possible.length))
          }

          const storageRef = fb.storage.ref()
          const metadata = {
            contentType: imageData.type
          }

          const newFilename = randomTxt + '.' + imageData.name.split('.').pop()
          const fileOrgName = imageData.name
          const imageTargetPath = 'projects/' + this.project.varycode + '/prospects/' + this.prospect.varycode + '/rooms/' + room.id + '_' + this.roomCategories[room.category] + '/products/' + product.varycode

          const uploadTask = storageRef.child(imageTargetPath + '/' + newFilename).put(imageData, metadata) // Upload the file

          uploadTask.on('state_changed', function (snapshot) {
            Vue.set(self.progresses, productObjectKey, (snapshot.bytesTransferred / snapshot.totalBytes) * 100)

            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
            }
          }, function (error) {
            switch (error.code) {
              case 'storage/unauthorized':
                console.log('Storage autorization required, access denied...')
                break
              case 'storage/canceled':
                console.log('The upload was cancelled')
                break
              case 'storage/unknown':
                console.log('An unknown error occurred during upload')
                break
            }
          }, function () {
            self.uploadingProductImage = self.uploadingProductImage.filter(item => item !== productObjectKey + '_' + type)

            const nextImageKey = product[type] !== undefined ? parseInt(Object.keys(product[type]).pop()) + 1 : 0

            fb.prospectsCollection.doc(self.prospect._id).update({
              [`rooms.${self.currentRoomKey}.products.${productObjectKey}.${type}.${nextImageKey}`]: {
                createdOn: new Date(),
                file: newFilename,
                orgFile: fileOrgName,
                path: imageTargetPath,
                meta: metadata,
                userId: self.$store.state.auth.userProfile.uid,
                deleted: false
              }
            }).then(() => {
              self.$bvToast.toast(self.trans('prospects.designer.image_uploaded_successfully'), {
                title: self.trans('global.success'),
                variant: 'success',
                solid: true
              })
            }).catch((err) => {
              // Do errorhandling here
              console.log('Error while updating room image:')
              console.log(err)
            })
          })
        } else {
          // Do error-handling here
          console.log('Error: Unable to find the image data in the form.')
        }
      },
      openLinkProductModal (prodKey) {
        this.productkey = prodKey // the current product we want to link to
        this.$bvModal.show('link-products-modal')
      },
      openEditProductModal (prodKey) {
        this.productkey = prodKey
        console.log('Using productKey:', this.productkey)
        this.$bvModal.show('edit-prospect-product-modal-' + prodKey)
      }
    }
  }
</script>

<style lang="scss" scoped>
  #add_room_btn {
    position: absolute;
    top: 0;
    right: 0;
  }

  .vary-image-container {
    display: flex;
    background-color: #fcfcfc;
    background-size: cover;
    border: 1px solid #DFE4EB;
    border-radius: 4px;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: 837px;
    overflow: hidden;

    &.noimg {
      padding-top: 66.67%;
      min-height: 390px;
    }

    .text {
      margin-top: -66.67%;
      color: #B5BCC6;
      text-align: center;

      i {
        margin-bottom: 20px;
      }

      span {
        display: block;
      }
    }
  }

  .vary-image-progress {
    transition: opacity .5s;

    margin-top: 16px;
    margin-bottom: -8px;
  }

  .empty-product-image {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    background-color: #545454;
  }

  #designerproducts {
    margin-top: 26px;

    /deep/ tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }

    .render-wrapper {
      position: relative;
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 4px;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: #fff;
      overflow: hidden;

      &.overlay-wrapper {
        width: 80px;
        height: 80px;
      }

      .select-label {
        width: 100%;
        height: 100%;
        margin-bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        transition-duration: .4s;

        &:hover {
          background-color: rgba(235, 235, 235, 0.8);

          > span {
            i {
              &.toggleme {
                display: inline-block;
              }
            }
          }
        }

        > span {
          text-align: center;

          i {
            font-size: 26px;

            &.toggleme {
              display: none;
            }
          }

          p {
            font-size: 12px;
            margin-bottom: 0;
            font-weight: 400;
          }
        }

        > input[type="file"] {
          display: none;
        }
      }

      .radial-progress-wrp {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -25px;
        margin-left: -25px;

        p {
          margin-bottom: 0;
        }
      }
    }

    .linked-wrapper {
      display: flex;
      width: 100px;
      height: 100px;

      align-items: center;

      border-radius: 4px;
      text-decoration: none;

      > div {
        flex: 0 0 auto;
        width: 100%;
        text-align: center;

        i {
          font-size: 26px;
          margin-bottom: 10px;
        }

        p {
          font-size: 12px;
          margin: 0;
        }
      }

      &.unlinked {
        opacity: .6;
      }

      &:hover {
        background-color: #c7c7c7;
      }
    }
  }
</style>
