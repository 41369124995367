<template>
  <b-link class="sortable-handle" :class="{'disabled': !sortable}">
    <i class="far" :class="sortable ? 'fa-grip-lines' : 'fa-ban'"></i>
  </b-link>
</template>

<script>
  export default {
    name: 'SortableHandle',
    props: {
      sortable: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
