<template>
  <div>
    <b-modal id="manage-room-products" scrollable size="lg">
      <template v-slot:modal-header>
        <h3>
          {{ trans('prospects.designer.form_manage_room_products.administrate_products') }}
        </h3>
        <p class="description">
          {{ trans('prospects.designer.form_manage_room_products.administrate_products_desc') }}
        </p>
      </template>

      <template v-slot:default>
        <b-container class="full-width">
          <b-row v-for="(category, i) in categories" :key="i">
            <b-col>
              <b-row>
                <b-col>
                  <h3 class="margin-top-26">
                    {{ category.title }}
                  </h3>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-table
                    id="room-product-list"
                    primary-key="productkey"
                    hover
                    striped
                    :items="tableData[i]"
                    :fields="tableFields"
                    :no-local-sorting="true"
                  >
                    <!-- begin:Cell Name -->
                    <template v-slot:cell(name)="row">
                      {{ row.item.name }}
                    </template>
                    <!-- end:Cell Name -->

                    <!-- begin:Cell Order (form) -->
                    <template v-slot:cell(order)="row">
                      <b-form inline @submit.prevent>
                        <b-form-input :value="row.item.order" class="form-control-lg" type="text" trim @keyup="updateProductOrder(row.item.productkey, row.item.categorykey, $event.target.value)"></b-form-input>
                      </b-form>
                    </template>
                    <!-- end:Cell Order (form) -->
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="light" size="md" class="padding-left-60 padding-right-60 float-right margin-top-26" @click="cancel()">
          {{ trans('global.close') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  const fb = require('../../../../../../firebaseConfig')

  export default {
    name: 'ModalManageRoomProducts',
    props: {
      prospect: {
        type: Object,
        required: true
      },
      roomkey: {
        type: Number,
        required: true
      },
      roomproducts: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        timer: []
      }
    },
    computed: {
      categories () {
        return this.prospect.rooms[this.roomkey].categories !== undefined && Object.keys(this.prospect.rooms[this.roomkey].categories).length > 0 ? this.prospect.rooms[this.roomkey].categories : false
      },
      tableFields () {
        return [
          { key: 'name', label: this.trans('global.name') },
          { key: 'order', label: this.trans('global.order') }
        ]
      },
      tableData () {
        const allProductsData = {}
        let data = []

        Object.keys(this.categories).map((categoryKey) => {
          if (this.categories[categoryKey].deleted === undefined || this.categories[categoryKey].deleted !== true) {
            Object.keys(this.prospect.rooms[this.roomkey].products).map((prodKey) => {
              if (
                this.prospect.rooms[this.roomkey].products[prodKey].category === categoryKey &&
                (
                  this.prospect.rooms[this.roomkey].products[prodKey].deleted === undefined ||
                  this.prospect.rooms[this.roomkey].products[prodKey].deleted !== true
                )
              ) {
                data.push({
                  productkey: prodKey,
                  categorykey: categoryKey,
                  name: this.prospect.rooms[this.roomkey].products[prodKey].name,
                  order: this.prospect.rooms[this.roomkey].products[prodKey].order !== undefined ? this.prospect.rooms[this.roomkey].products[prodKey].order : 0
                })
              }
            })

            allProductsData[categoryKey] = data
            data = []
          }
        })

        return allProductsData
      }
    },
    mounted () {
      this.$root.$on('bv::modal::hide', () => { })
    },
    methods: {
      updateProductOrder (productKey, catKey, value) {
        const self = this

        if (productKey !== undefined && value !== undefined) {
          this.inputTimer(productKey).then(() => {
            fb.prospectsCollection.doc(this.prospect._id).update({
              [`rooms.${self.roomkey}.products.${productKey}.order`]: value
            }).then(() => {
              this.$bvToast.toast(this.trans('prospects.designer.form_manage_room_products.product_updated', { category: self.prospect.rooms[self.roomkey].categories[catKey].title, product: self.prospect.rooms[self.roomkey].products[productKey].name }), {
                title: this.trans('global.success'),
                variant: 'success',
                solid: true
              })
            }).catch((err) => {
              this.$bvToast.toast(this.trans('prospects.designer.form_manage_room_products.product_updated', { category: self.prospect.rooms[self.roomkey].categories[catKey].title, product: self.prospect.rooms[self.roomkey].products[productKey].name }), {
                title: this.trans('global.error'),
                variant: 'warning',
                solid: true
              })
              console.log(err)
            })
          })
        }
      },
      inputTimer (productKey) {
        return new Promise((resolve) => {
          if (this.timer[productKey]) {
            clearTimeout(this.timer[productKey])
            this.timer[productKey] = null
          }

          this.timer[productKey] = setTimeout(() => {
            resolve()
          }, 600)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #room-product-list {

    /deep/ thead {
      tr {
        th {
          width: 50%;
          border: none;
        }
      }
    }

    /deep/ tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }
</style>
