<template>
  <b-container id="unit-overview" class="margin-top-60">
    <b-row class="align-content-strech flex-wrap">
      <b-col sm="6">
        <vary-card :title="trans('global.statistics')" :description="trans('prospects.statistics_description')" class="margin-bottom-30">
          <div style="text-align: center; margin-bottom: 80px;">
            <i class="fas fa-chart-line margin-top-60" style="font-size: 50px; opacity: 0.2;"></i>
          </div>
        </vary-card>
      </b-col>
      <b-col sm="6">
        <vary-card :title="trans('global.leads')" :description="trans('prospects.leads_description')" class="margin-bottom-30">
          <div style="text-align: center; margin-bottom: 80px;">
            <i class="fas fa-shopping-bag margin-top-60" style="font-size: 50px; opacity: 0.2;"></i>
          </div>
        </vary-card>
      </b-col>
    </b-row>
    <b-row class="margin-top-26">
      <b-col sm="6">
        <vary-card :title="trans('global.userconfigurations')" :description="trans('prospects.userconfigurations_description')" class="margin-bottom-30">
          <div style="text-align: center; margin-bottom: 80px;">
            <i class="fas fa-shopping-bag margin-top-60" style="font-size: 50px; opacity: 0.2;"></i>
          </div>
        </vary-card>
      </b-col>
      <b-col sm="6">
        <vary-card :title="trans('global.references')" :description="trans('prospects.references_description')" class="margin-bottom-30">
          <div style="text-align: center; margin-bottom: 80px;">
            <i class="fas fa-shopping-bag margin-top-60" style="font-size: 50px; opacity: 0.2;"></i>
          </div>
        </vary-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import VaryCard from '../../../../Core/Card'

  export default {
    name: 'ProjectSalesProspectDashboard',
    components: {
      'vary-card': VaryCard
    },
    methods: {
      toggleTab (tab) {
        this.$store.commit('configs/setProjectTopbarMenu', tab)
      }
    }
  }
</script>
