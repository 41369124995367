<template>
  <div>
    <b-form @submit.prevent>
      <b-modal
        id="link-products-modal"
        size="lg"
        body-class="formified"
        footer-class="loadingfix"
        no-stacking
        scrollable
        @shown="resetData"
      >
        <template v-slot:modal-header>
          <h3>{{ trans('prospects.designer.form_link_products.create_link') }}</h3>
          <p class="description">
            {{ trans('prospects.designer.form_link_products.link_products') }}
          </p>
        </template>

        <template v-slot:default>
          <b-row v-if="linkedProducts" class="margin-bottom-30">
            <b-col>
              <p class="font-weight-bold m-0">
                {{ trans('prospects.designer.linked_products') }}
              </p>
            </b-col>
            <b-col v-for="(product, index) in linkedProducts" :key="index + '-' + product.name" sm="12">
              <b-card class="margin-top-16 vary-list-card">
                <b-row>
                  <b-col class="col-sm-auto">
                    <b-img v-if="product.image" :src="product.image"></b-img>
                    <div v-else style="background-color: green; opacity: 0.2; width: 81px; height: 54px; border-radius: 4px;"></div>
                  </b-col>
                  <b-col>
                    {{ product.product.name }}
                  </b-col>
                  <b-col>
                    {{ product.room.name }}
                  </b-col>
                  <b-col>
                    <b-button :ref="'removelink' + index + product.room.name" variant="primary" size="md" :style="[ submitbtnwidth ? { 'width': submitbtnwidth + 'px' } : { 'width': 'auto'} ]" @click="removeLink(product.roomKey)">
                      <span>{{ trans('prospects.designer.remove_link') }}</span>
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group id="product_room_group" :label="trans('prospects.designer.form_link_products.roomid_title')" label-for="product_room">
                <b-form-select
                  id="product_room"
                  v-model="roomId"
                  name="product_room"
                  size="lg"
                  class="form-control-lg"
                  @change="getRoom($event)"
                >
                  <option :value="null">
                    {{ trans('prospects.designer.form_link_products.roomid_placeholder') }}
                  </option>
                  <option v-for="( unit, index ) in allOtherRooms" :key="index + '-' + unit.name" :value="index">
                    {{ unit.name }}
                  </option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <p class="font-weight-bold">
                {{ trans('prospects.designer.form_link_products.choose_product') }}
              </p>
            </b-col>
          </b-row>
          <b-row v-if="products">
            <b-col>
              <b-table
                id="room-products-table"
                striped
                hover
                :items="items"
                :fields="fields"
                sort-by="name"
                :sort-desc="false"
              >
                <template v-slot:cell(image)="row">
                  <b-col class="col-auto">
                    <b-img v-if="row.item.image.image" :src="row.item.image.image + '?w=100&fit=crop&mask=corners&corner-radius=5,5,5,5'"></b-img>
                    <div v-else class="empty-product-image"></div>
                  </b-col>
                </template>

                <template v-slot:cell(name)="row">
                  <b-col class="font-size-large">
                    {{ row.item.name }}
                  </b-col>
                </template>

                <template v-slot:cell(cat)="row">
                  <b-col class="font-size-large">
                    {{ row.item.cat }}
                  </b-col>
                </template>

                <template v-slot:cell(actions)="row">
                  <b-col class="ml-auto">
                    <b-button :ref="'submit' + row.item.image.key" variant="primary" size="md" :style="[ submitbtnwidth ? { 'width': submitbtnwidth + 'px' } : { 'width': 'auto'} ]" @click="linkProduct(row.item.image.key)">
                      <span v-if="!loading[row.item.image.key]">{{ trans('prospects.designer.form_link_products.link_products_button') }}</span>
                      <b-spinner v-else variant="white"></b-spinner>
                    </b-button>
                  </b-col>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <div class="alert alert-warning">
                <i class="fas fa-exclamation-triangle padding-right-6"></i> {{ trans('prospects.designer.form_link_products.choose_room_first') }}
              </div>
            </b-col>
          </b-row>
        </template>

        <template v-slot:modal-footer="{ cancel }">
          <b-button variant="light" size="md" class="padding-left-60 padding-right-60" @click="cancel()">
            {{ trans('global.complete') }}
          </b-button>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>

<script>
  import Vue from 'vue'
  const fb = require('../../../../../../firebaseConfig')
  export default {
    name: 'ModalLinkProducts',
    props: {
      prospect: {
        type: Object,
        required: true
      },
      roomkey: {
        type: Number,
        required: true
      },
      productkey: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        currentRoom: this.prospect.rooms[this.roomkey],
        linkedRoom: false,
        linkedRoomKey: false,
        allRooms: this.prospect.rooms,
        submitbtnwidth: null,
        roomIdValue: null,
        roomId: null,
        products: false,
        loading: []
      }
    },
    computed: {
      // linkedProducts (): Returns all products with links to other products, including information
      // about the linked products
      linkedProducts () {
        let products = {}

        if (this.prospect.rooms[this.roomkey].products[this.productkey].links !== undefined && Object.keys(this.prospect.rooms[this.roomkey].products[this.productkey].links).length > 0) {
          Object.keys(this.prospect.rooms[this.roomkey].products[this.productkey].links).map((linkedRoomKey, index) => {
            products[index] = {}
            products[index].roomKey = linkedRoomKey
            products[index].prodKey = this.prospect.rooms[this.roomkey].products[this.productkey].links[linkedRoomKey]
            products[index].room = this.prospect.rooms[linkedRoomKey]
            products[index].product = this.prospect.rooms[linkedRoomKey].products[this.prospect.rooms[this.roomkey].products[this.productkey].links[linkedRoomKey]]

            const image = products[index].product.image !== undefined && Object.keys(products[index].product.image).length > 0 ? products[index].product.image[Object.keys(products[index].product.image).pop()] : false

            products[index].image = process.env.VUE_APP_IMAGE_CDN_URL + '/' + image.path + '/' + image.file + '?fit=crop&w=81&h=54&mask=corners&corner-radius=5,5,5,5'
          })
        } else {
          products = false
        }

        return products
      },
      // allOtherRooms (): Returns all other rooms than the current one
      allOtherRooms () {
        const rooms = {}

        Object.keys(this.allRooms).map((roomKey) => {
          if (parseInt(roomKey) !== parseInt(this.roomkey)) {
            rooms[roomKey] = this.allRooms[roomKey]
          }
        })

        return rooms
      },
      fields () {
        return [
          { key: 'image', label: this.trans('global.productinformation'), sortable: false },
          { key: 'name', label: this.trans('global.name'), sortable: true },
          { key: 'cat', label: this.trans('global.category'), sortable: true },
          { key: 'actions', label: 'Actions' }
        ]
      },
      items () {
        const data = []
        const products = this.products

        Object.keys(products).map((productKey) => {
          const prod = products[productKey]

          if (prod.deleted === undefined || prod.deleted !== true) {
            const currentProduct = {}

            // Product thumbnail data
            if (prod.image !== undefined) {
              const imageKey = Object.keys(prod.image).length > 1 ? Object.keys(prod.image).pop() : 0
              currentProduct.imageurl = process.env.VUE_APP_IMAGE_CDN_URL + '/' + prod.image[imageKey].path + '/' + prod.image[imageKey].file
            } else {
              currentProduct.imageurl = false
            }

            let catName = ''
            // Category name
            if (!isNaN(prod.category) && this.prospect.rooms[this.linkedRoomKey].categories !== undefined && Object.keys(this.prospect.rooms[this.linkedRoomKey].categories).length > 0) {
              console.log(prod.category)
              console.log(this.prospect.rooms[this.linkedRoomKey].categories)
              catName = this.prospect.rooms[this.linkedRoomKey].categories[prod.category].title
            }

            data.push({
              image: { image: currentProduct.imageurl, key: productKey },
              name: prod.name,
              cat: catName
            })
          } // else the product is deleted
        })

        return data
      }
    },
    methods: {
      // getRoom (id): Fetches the selected room-data
      getRoom (roomKey) {
        if (roomKey !== null && roomKey !== undefined) {
          this.linkedRoom = this.prospect.rooms[roomKey]
          this.linkedRoomKey = roomKey
          this.products = this.prospect.rooms[roomKey].products !== undefined && Object.keys(this.prospect.rooms[roomKey].products).length > 0 ? this.prospect.rooms[roomKey].products : false

          // Prepare the loading array for all the button-states
          Object.keys(this.products).forEach((key) => {
            this.loading[key] = false
          })
        } else {
          this.products = false
        }

        return true
      },
      // nestedSortCompare (): Used when we have nested objects in the items object
      nestedSortCompare (aRow, bRow, key) {
        if (key === 'info') { // Info is a field with nested values (object in object)
          const a = aRow[key].name
          const b = bRow[key].name
          return this.toString(a).localeCompare(this.toString(b), 'no')
        } else {
          return false
        }
      },
      // toString (): Bootstrap toString helper value from the original Bootstrap GIT-repo
      toString (value) {
        if (value === null || typeof value === 'undefined') {
          return ''
        } else if (value instanceof Object) {
          return Object.keys(value)
            .sort()
            .map(key => toString(value[key]))
            .join(' ')
        } else {
          return String(value)
        }
      },
      removeLink (targetRoomKey) {
        const self = this
        const links = {}

        Object.keys(this.prospect.rooms[this.roomkey].products[this.productkey].links).map((roomKey) => {
          links[roomKey] = this.prospect.rooms[this.roomkey].products[this.productkey].links[roomKey]
        })

        const updateQuery = {}
        // Remove link to the targetProduct on the current product
        updateQuery[`rooms.${this.roomkey}.products.${this.productkey}.links.${targetRoomKey}`] = fb.firebase.firestore.FieldValue.delete()
        Object.keys(links).map((roomKey) => {
          if (roomKey !== targetRoomKey) {
            // Remove link to the targetProduct from all other products
            updateQuery[`rooms.${roomKey}.products.${links[roomKey]}.links.${targetRoomKey}`] = fb.firebase.firestore.FieldValue.delete()
          } else {
            // Remove all links from the targetProduct
            updateQuery[`rooms.${roomKey}.products.${links[roomKey]}.links`] = fb.firebase.firestore.FieldValue.delete()
          }
        })

        fb.prospectsCollection.doc(this.prospect._id).update(updateQuery).then(() => {
          self.currentRoom = self.prospect.rooms[self.roomkey] // Update the currentRoom variable

          self.$bvToast.toast(self.trans('prospects.designer.form_link_products.link_product_removed'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
        }).catch((err) => {
          console.log('There was a error while removing the linked products:')
          console.log(err)
        })
      },
      /*
       * linkProduct ():
       * How it works:
       * 1. Check if there's already a product in the target room, remove it to avoid duplicates
       * 2. Updates the current product with the new link-information
       * 3. Updates all products in the "links" object with the current product data
       * 4. Updates all products in the "links" object with ALL the linked products info
       */
      linkProduct (targetProductKey) {
        console.log(' ')
        console.log('linkProduct()')
        console.log(' ')

        const self = this
        const updateQuery = {}
        Vue.set(this.loading, targetProductKey, true)
        this.currentRoom = this.prospect.rooms[this.roomkey]

        this.submitbtnwidth = this.$refs['submit' + targetProductKey] ? this.$refs['submit' + targetProductKey].clientWidth + 2 : false

        if (this.currentRoom.products[this.productkey].links !== undefined && Object.keys(this.currentRoom.products[self.productkey].links).length > 0) {
          Object.keys(this.currentRoom.products[this.productkey].links).map((linkedProductRoomKey) => {
            if (linkedProductRoomKey === this.linkedRoomKey) {
              fb.prospectsCollection.doc(this.prospect._id).update({
                [`rooms.${self.linkedRoomKey}.products.${self.currentRoom.products[self.productkey].links[self.linkedRoomKey]}.links`]: fb.firebase.firestore.FieldValue.delete()
              }).then(() => {
                self.currentRoom = self.prospect.rooms[self.roomkey] // Update the currentRoom variable
              }).catch((err) => {
                console.log('There was a error while removing the linked products:')
                console.log(err)
              })
            }
          })
        } // else, no linked products hence nothing to delete

        fb.prospectsCollection.doc(this.prospect._id).update({
          [`rooms.${this.roomkey}.products.${this.productkey}.links.${this.linkedRoomKey}`]: targetProductKey // Update the current product with the new link-information
        }).then(() => {
          self.currentRoom = self.prospect.rooms[self.roomkey] // Update the currentRoom variable

          // the linked products must get reference back to the current product and eachother
          Object.keys(self.currentRoom.products[self.productkey].links).map((linkedProductRoomKey) => {
            updateQuery[`rooms.${linkedProductRoomKey}.products.${self.currentRoom.products[self.productkey].links[linkedProductRoomKey]}.links.${self.roomkey}`] = self.productkey

            // Update the linked products with all the other products the current product is linked with - but not itself
            Object.keys(self.currentRoom.products[self.productkey].links).map((linkedProductRoomKeySub) => {
              if (linkedProductRoomKeySub !== linkedProductRoomKey) {
                updateQuery[`rooms.${linkedProductRoomKey}.products.${self.currentRoom.products[self.productkey].links[linkedProductRoomKey]}.links.${linkedProductRoomKeySub}`] = self.currentRoom.products[self.productkey].links[linkedProductRoomKeySub]
              }
            })
          })

          fb.prospectsCollection.doc(this.prospect._id).update(updateQuery).then(() => {
            self.currentRoom = self.prospect.rooms[self.roomkey] // Update the currenRoom with the new data
            Vue.set(this.loading, targetProductKey, false)

            self.$bvToast.toast(self.trans('prospects.designer.form_link_products.link_product_success'), {
              title: self.trans('global.success'),
              variant: 'success',
              solid: true
            })
          }).catch((err) => {
            Vue.set(this.loading, targetProductKey, false)
            console.log('Update of related-products failed:')
            console.log(err)
          })
        }).catch((err) => {
          console.log('Something went wrong....')
          console.log(err)
        })

        return true
      },
      resetData () {
        this.roomId = null
        this.products = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  #room-products-table {
    margin-top: 26px;

    td {
      &.cell {
        vertical-align: center;
      }
    }

    .row-wrp {
      display: flex;
      flex-wrap: nowrap;
      margin-left: -15px;
      margin-right: -15px;

      align-items: center;

      .col {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
      }
    }
  }
</style>
