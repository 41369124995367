<template>
  <div>
    <b-modal
      id="delete-category-modal"
      size="lg"
      body-class="formified"
      footer-class="loadingfix"
      scrollable
    >
      <template v-slot:modal-header>
        <h3>{{ trans('prospects.designer.form_delete_category.delete_category', { cat: catTitle }) }}</h3>
        <p class="description">
          {{ trans('prospects.designer.form_delete_category.delete_category_description') }}
        </p>
      </template>

      <template v-slot:default>
        <div v-if="numberOfProductsInThisCategory > 0">
          <b-row>
            <b-col>
              <div class="alert alert-danger">
                <i class="fas fa-exclamation-triangle padding-right-6"></i> {{ trans('prospects.designer.form_delete_category.delete_alert') }}
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table
                id="conflicting_products_table"
                striped
                hover
                :items="items"
                :fields="fields"
                sort-by="name"
                :sort-desc="false"
                thead-class="text-left"
              >
                <template v-slot:cell(image)="row">
                  <b-img v-if="row.item.image" :src="row.item.image + '?w=100&fit=crop&mask=corners&corner-radius=5,5,5,5'"></b-img>
                </template>

                <template v-slot:cell(name)="row">
                  <span>{{ row.item.name }}</span>
                </template>

                <template v-slot:cell(varycode)="row">
                  <span>{{ row.item.varycode }}</span>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
        <b-row v-else>
          <b-col>
            <div class="alert alert-success">
              <i class="far fa-thumbs-up padding-right-6"></i> {{ trans('prospects.designer.form_delete_category.delete_safe') }}
            </div>
          </b-col>
        </b-row>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="light" size="md" class="padding-left-60 padding-right-60" @click="cancel()">
          {{ trans('global.cancel') }}
        </b-button>
        <b-button variant="danger" size="md" class="padding-left-60 padding-right-60" @click="deleteCategory(categorykey)">
          <span v-if="numberOfProductsInThisCategory > 0">
            {{ trans('prospects.designer.form_delete_category.delete_confirm_all', { cat: catTitle, count: numberOfProductsInThisCategory }) }}
          </span>
          <span v-else>
            {{ trans('prospects.designer.form_delete_category.delete_confirm', { cat: catTitle }) }}
          </span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import * as objectPath from 'object-path'
  const fb = require('../../../../../../firebaseConfig')

  export default {
    name: 'ModalDeleteCategory',
    props: {
      prospect: {
        type: Object,
        required: true
      },
      categories: {
        type: Object,
        required: true
      },
      categorykey: {
        type: String,
        required: true
      },
      roomproducts: {
        type: Object,
        required: true
      },
      roomkey: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        catTitle: ''
      }
    },
    computed: {
      productsInThisCategory () {
        const products = {}
        const self = this

        Object.keys(this.roomproducts).filter((productKey) => {
          if (self.categorykey !== null && self.roomproducts[productKey].category === self.categorykey) {
            products[productKey] = self.roomproducts[productKey]
          }
        })

        return products
      },
      numberOfProductsInThisCategory () {
        return Object.keys(this.productsInThisCategory).length
      },
      fields () {
        return [
          { key: 'image', label: this.trans('global.productimage') },
          { key: 'name', label: this.trans('global.name') },
          { key: 'varycode', label: this.trans('global.varycode') }
        ]
      },
      items () {
        const data = []

        Object.keys(this.productsInThisCategory).forEach((prodKey) => {
          const product = this.productsInThisCategory[prodKey]
          const currentProduct = {}

          // Product thumbnail data
          if (product.image !== undefined) {
            const imageKey = Object.keys(product.image).length > 1 ? Object.keys(product.image)[Object.keys(product.image).length - 1] : 0
            currentProduct.imageurl = process.env.VUE_APP_IMAGE_CDN_URL + '/' + product.image[imageKey].path + '/' + product.image[imageKey].file
          } else {
            currentProduct.imageurl = false
          }

          data.push({
            image: currentProduct.imageurl,
            name: product.name,
            varycode: product.varycode
          })
        })

        return data
      }
    },
    watch: {
      categorykey (old, upd) {
        this.setCatTitle()
      }
    },
    methods: {
      setCatTitle () {
        this.catTitle = objectPath.get(this.categories, `${this.categorykey}.title`, 'ukjent kategori')
      },
      deleteCategory (catKey) {
        const self = this

        const catQuery = {}

        catQuery[`rooms.${self.roomkey}.categories.${catKey}.deleted`] = true // "Delete the category"

        // Remove category from all other products in this room
        if (self.numberOfProductsInThisCategory > 0) {
          Object.keys(self.productsInThisCategory).forEach((prodKey) => {
            catQuery[`rooms.${self.roomkey}.products.${prodKey}.category`] = null
          })
        }

        console.log('Query:', catQuery)

        fb.prospectsCollection.doc(this.prospect._id).update(catQuery).then(() => {
          self.$root.$emit('bv::hide::modal', 'delete-category-modal')
          self.$bvToast.toast(self.trans('prospects.designer.form_delete_category.delete_success'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
        }).catch((err) => {
          self.$root.$emit('bv::hide::modal', 'delete-category-modal')
          self.$bvToast.toast(self.trans('prospects.designer.form_delete_category.delete_error'), {
            title: self.trans('global.error'),
            variant: 'warning',
            solid: true
          })
          console.log(err)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #conflicting_products_table {
    margin-top: 26px;

    /deep/ thead {
      tr {
        th {
          &:first-child {
            margin-right: auto;
          }
        }
      }
    }

    /deep/ tbody {
      tr {
        td {
          vertical-align: middle;

          &:first-child {
            width: 120px;
          }
        }
      }
    }
  }
</style>
