<template>
  <b-modal id="add-existing-prodict-to-room-modal" scrollable size="lg" no-stacking @shown="createLoadingArray">
    <template v-slot:modal-header>
      <h3>{{ trans('prospects.designer.form_add_room_product.add') }}</h3>
      <p class="description">
        {{ trans('prospects.designer.form_add_room_product.add_description') }}
      </p>
    </template>

    <template v-slot:default>
      <b-row>
        <b-col>
          <div class="modal-header-wrp">
            <h4>{{ trans('global.products') }}</h4>
            <b-button v-b-modal.add-room-new-product-modal variant="primary" size="sm" class="padding-left-16 padding-right-16">
              {{ trans('prospects.designer.form_add_room_product.add_new') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            :label="trans('global.filter')"
            label-cols-sm="1"
            label-align-sm="right"
            label-align-md="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                :placeholder="trans('prospects.designer.form_add_room_product.filter_on_product_name')"
                class="col-sm-6"
              ></b-form-input>
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            id="products-table"
            striped
            hover
            :items="items"
            :fields="fields"
            :filter="filter"
            :sort-compare="nestedSortCompare"
            sort-by="info"
            :sort-desc="false"
          >
            <template v-slot:cell(info)="row">
              <div class="row-wrp">
                <b-col class="col-auto">
                  <b-img v-if="row.item.info.image" :src="row.item.info.image + '?w=100&fit=crop&mask=corners&corner-radius=5,5,5,5'"></b-img>
                  <div v-else class="empty-product-image"></div>
                </b-col>
                <b-col class="font-size-large">
                  {{ row.item.info.name }}
                </b-col>
                <b-col class="ml-auto">
                  <b-button
                    v-if="processing && !loading[row.item.info.key]"
                    :ref="'submitbtn_' + row.item.info.key"
                    variant="primary"
                    size="md"
                    :style="[ submitbtnwidth ? { 'width': submitbtnwidth + 'px' } : { 'width': 'auto'} ]"
                    disabled
                  >
                    <span v-if="!loading[row.item.info.key]">{{ trans('prospects.designer.add') }}</span>
                  </b-button>
                  <b-button
                    v-else
                    :ref="'submitbtn_' + row.item.info.key"
                    variant="primary"
                    size="md"
                    :style="[ submitbtnwidth ? { 'width': submitbtnwidth + 'px' } : { 'width': 'auto'} ]"
                    @click="addProduct(row.item.info.key)"
                  >
                    <span v-if="!loading[row.item.info.key]">{{ trans('prospects.designer.add') }}</span>
                    <b-spinner v-else variant="white"></b-spinner>
                  </b-button>
                </b-col>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </template>

    <template v-slot:modal-footer="{ cancel }">
      <b-button variant="light" size="md" class="padding-left-60 padding-right-60" @click="cancel()">
        {{ trans('global.complete') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
  import Vue from 'vue'
  import { mapState } from 'vuex'

  const fb = require('../../../../../../firebaseConfig')

  export default {
    name: 'ModalAddRoomProduct',
    props: {
      project: {
        type: Object,
        required: true
      },
      prospect: {
        type: Object,
        required: true
      },
      roomkey: {
        type: Number,
        required: true
      },
      roomcategory: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        submitbtnwidth: null,
        loading: [],
        filter: null,
        processing: false
      }
    },
    computed: {
      ...mapState({
        products: state => state.products.products,
        userProfile: state => state.auth.userProfile
      }),
      fields () {
        return [
          { key: 'info', label: this.trans('global.productinformation'), sortable: true }
        ]
      },
      items () {
        const data = []
        const prod = this.products

        Object.keys(prod).map((key) => {
          if (prod[key].deleted === undefined || prod[key].deleted !== true) {
            const product = prod[key]
            const currentProduct = {}

            // Product thumbnail data
            if (product.image !== undefined) {
              const imagedataId = Object.keys(product.image).length > 1 ? Object.keys(product.image)[Object.keys(product.image).length - 1] : 0
              currentProduct.imageurl = process.env.VUE_APP_IMAGE_CDN_URL + '/' + product.image[imagedataId].path + '/' + product.image[imagedataId].file
            } else {
              currentProduct.imageurl = false
            }

            data.push({
              info: { image: currentProduct.imageurl, name: product.name, key }
            })
          } // else -> product has been soft-deleted
        })

        return data
      }
    },
    methods: {
      // nestedSortCompare (): Used when we have nested objects in the items object
      nestedSortCompare (aRow, bRow, key) {
        if (key === 'info') { // Info is a field with nested values (object in object)
          const a = aRow[key].name
          const b = bRow[key].name
          return this.toString(a).localeCompare(this.toString(b), 'no')
        } else {
          return false
        }
      },
      // toString (): Bootstrap toString helper value from the original Bootstrap GIT-repo
      toString (value) {
        if (value === null || typeof value === 'undefined') {
          return ''
        } else if (value instanceof Object) {
          return Object.keys(value)
            .sort()
            .map(key => toString(value[key]))
            .join(' ')
        } else {
          return String(value)
        }
      },
      addProduct (id) {
        let nextProductId = ''
        // Check if the last productID is a valid number, else replace it with the new valid product
        // If a bug occurs, the last product might become "undefined", and this will clean that up (messy, but works for now)
        if (this.prospect.rooms[this.roomkey].products !== undefined && Object.keys(this.prospect.rooms[this.roomkey].products).length > 0) {
          if (!isNaN(parseInt(Object.keys(this.prospect.rooms[this.roomkey].products)[Object.keys(this.prospect.rooms[this.roomkey].products).length - 1]))) {
            nextProductId = parseInt(Object.keys(this.prospect.rooms[this.roomkey].products).pop()) + 1
          } else { // Fetch the previous product ID and delete the invalid one
            nextProductId = parseInt(Object.keys(this.prospect.rooms[this.roomkey].products)[Object.keys(this.prospect.rooms[this.roomkey].products).length - 2]) + 1
            fb.prospectsCollection.doc(this.prospect._id).update({
              [`rooms.${this.roomkey}.products.${Object.keys(this.prospect.rooms[this.roomkey].products).pop()}`]: fb.firebase.firestore.FieldValue.delete()
            })
          }
        } else {
          nextProductId = 0
        }

        let product = {}
        let parentId = null
        this.submitbtnwidth = this.$refs['submitbtn_' + id] ? this.$refs['submitbtn_' + id].clientWidth : 'auto'
        this.processing = true
        Vue.set(this.loading, id, true)

        if (id in this.products) { // From Vuex store
          product = this.products[id]
          parentId = product._id // Only available from the Vuex store
        } else { // From DB?
          // Todo: fallback to manually fetch the data from the DB
        }

        if (!isNaN(nextProductId)) {
          // const copyImage = fb.func.httpsCallable('copyImage')
          // const mostRecentProductImage = product.image != null && product.image.constructor.name === 'Object' && Object.keys(product.image).length > 0 ? product.image[Object.keys(product.image).pop()] : ''

          // if (mostRecentProductImage !== '') { // The product has atleast one image and we'll copy it to the prospect product folder
          //   return copyImage({ filename: mostRecentProductImage.file, path: mostRecentProductImage.path, projectVaryCode: self.project.varycode, prospectVaryCode: self.prospect.varycode, productVaryCode: product.varycode, roomId: self.roomkey, roomCategory: self.roomcategory }).then((cloudFunctionResult) => {
          //     // Create a new images object so we only get the latest image we just copied to the
          //     // prospect product folder... ignoring the other images for now.
          //     return {
          //       0: {
          //         createdOn: new Date(),
          //         file: mostRecentProductImage.file,
          //         orgFile: mostRecentProductImage.orgFile,
          //         path: cloudFunctionResult.data.path,
          //         metadata: mostRecentProductImage.metadata,
          //         userId: self.$store.state.auth.userProfile.uid,
          //         deleted: false
          //       }
          //     }
          //   }).then((imagesObject) => {
          //     return fb.prospectsCollection.doc(self.prospect._id).update({
          //       [`rooms.${this.roomkey}.products.${nextProductId}`]: {
          //         createdOn: new Date(),
          //         varycode: product.varycode,
          //         name: product.name,
          //         slug: product.slug,
          //         shorttxt: product.shorttxt,
          //         longtxt: product.longtxt,
          //         userId: this.userProfile.uid,
          //         image: imagesObject,
          //         parentId: parentId,
          //         deleted: false
          //       }
          //     }).then(() => {
          //       this.$bvToast.toast(this.trans('prospects.designer.form_add_room_new_product.product_added_to_prospect'), {
          //         title: this.trans('global.success'),
          //         variant: 'success',
          //         solid: true
          //       })
          //
          //       this.processing = false
          //       Vue.set(this.loading, id, false)
          //       this.$root.$emit('bv::hide::modal', 'add-room-product-modal')
          //     })
          //   })
          // } else { // Product has no images, nothing to copy, should generate a error...
          //   return fb.prospectsCollection.doc(this.prospect._id).update({
          //     [`rooms.${this.roomkey}.products.${nextProductId}`]: {
          //       createdOn: new Date(),
          //       varycode: product.varycode,
          //       name: product.name,
          //       slug: product.slug,
          //       shorttxt: product.shorttxt,
          //       longtxt: product.longtxt,
          //       userId: this.userProfile.uid,
          //       image: product.image,
          //       parentId: parentId,
          //       deleted: false
          //     }
          //   }).then(() => {
          //     this.$bvToast.toast(this.trans('prospects.designer.form_add_room_new_product.product_added_to_prospect'), {
          //       title: this.trans('global.success'),
          //       variant: 'success',
          //       solid: true
          //     })
          //
          //     this.processing = false
          //     Vue.set(this.loading, id, false)
          //     this.$root.$emit('bv::hide::modal', 'add-room-product-modal')
          //   })
          // }

          // For now, we copy the product data directly - no need to copy the image to the prospect
          const productData = {
            createdOn: new Date(),
            varycode: product.varycode,
            name: product.name,
            slug: product.slug,
            shorttxt: product.shorttxt,
            longtxt: product.longtxt,
            userId: this.userProfile.uid,
            image: product.image,
            images: product.images !== undefined ? product.images : [],
            parentId,
            deleted: false
          }

          if (product.price !== undefined) productData.price = product.price

          return fb.prospectsCollection.doc(this.prospect._id).update({
            [`rooms.${this.roomkey}.products.${nextProductId}`]: productData
          }).then(() => {
            this.$bvToast.toast(this.trans('prospects.designer.form_add_room_new_product.product_added_to_prospect'), {
              title: this.trans('global.success'),
              variant: 'success',
              solid: true
            })

            this.processing = false
            Vue.set(this.loading, id, false)
            this.$root.$emit('bv::hide::modal', 'add-room-product-modal')
          })
        } else {
          this.$bvToast.toast(this.trans('prospects.designer.form_add_room_new_product.product_added_to_prospect_failed'), {
            title: this.trans('global.error'),
            variant: 'warning',
            solid: true
          })

          this.processing = false
          Vue.set(this.loading, id, false)
          this.$root.$emit('bv::hide::modal', 'add-room-product-modal')
        }
      },
      createLoadingArray () {
        Object.keys(this.products).forEach((key) => {
          this.loading[key] = false
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .modal-header-wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin-bottom: 0;
    }
  }

  #products-table {
    margin-top: 26px;

    td {
      &.cell {
        vertical-align: center;
      }
    }

    .row-wrp {
      display: flex;
      flex-wrap: nowrap;
      margin-left: -15px;
      margin-right: -15px;

      align-items: center;

      .col {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
      }
    }
  }
</style>
