<template>
  <div>
    <b-modal id="manage-rooms-modal" scrollable no-stacking size="lg">
      <template v-slot:modal-header>
        <h3>{{ trans('prospects.designer.form_manage_rooms.administrate_rooms') }}</h3>
        <p class="description">
          {{ trans('prospects.designer.form_manage_rooms.administrate_rooms_desc') }}
        </p>
      </template>

      <template v-slot:default>
        <b-container class="full-width">
          <b-table
            id="room-product-list"
            primary-key="productkey"
            hover
            striped
            :items="tableData"
            :fields="tableFields"
            :no-local-sorting="true"
            fixed
          >
            <!-- begin::cell category -->
            <template v-slot:cell(category)="row">
              {{ row.item.category }}
            </template>
            <!-- end::cell category -->

            <!-- begin::cell name -->
            <template v-slot:cell(name)="row">
              <b-form inline @submit.prevent>
                <b-form-input
                  :ref="'roomname-' + row.item.roomkey"
                  v-model="row.item.name"
                  :value="row.item.name"
                  class="form-control-lg"
                  type="text"
                  trim
                >
                </b-form-input>
              </b-form>
            </template>
            <!-- end::cell name -->

            <!-- begin:Cell Order (form) -->
            <template v-slot:cell(order)="row">
              <b-form inline @submit.prevent>
                <b-form-input :value="row.item.order" class="form-control-lg" type="text" trim @keyup="updateRoomOrder(row.item.roomkey, $event.target.value)"></b-form-input>
              </b-form>
            </template>
            <!-- end:Cell Order (form) -->

            <!-- begin::cell actions -->
            <template v-slot:cell(actions)="row">
              <b-button
                :ref="'roomsavedata-' + row.item.roomkey"
                variant="primary"
                :style="btnWidth !== 0 ? 'width: ' + btnWidth + 'px;' : ''"
                @click="updateRoomName(row.item)"
              >
                <span v-if="!buttonIsLoading[row.item.roomkey]">
                  {{ trans('global.save') }}
                </span>
                <b-spinner v-else variant="white"></b-spinner>
              </b-button>

              <b-button
                :ref="'roomdelete-' + row.item.roomkey"
                variant="danger"
                :style="btnWidth !== 0 ? 'width: ' + btnWidth + 'px;' : ''"
                class="ml-2"
                :disabled="parseInt(currentRoomKey) === parseInt(row.item.roomkey)"
                @click="deleteRoom(row.item)"
              >
                <span v-if="!buttonIsLoading[row.item.roomkey + 'delete']">
                  {{ trans('global.delete') }}
                </span>
                <b-spinner v-else variant="white"></b-spinner>
              </b-button>
            </template>
            <!-- end::cell actions -->
          </b-table>
        </b-container>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="light" size="md" class="padding-left-60 padding-right-60 float-right margin-top-26" @click="cancel()">
          {{ trans('global.close') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  const fb = require('../../../../../../firebaseConfig')
  const varyConfig = require('../../../../../../varyConfig')

  export default {
    name: 'ModalManageRooms',
    props: {
      prospect: {
        type: Object,
        required: true
      },
      currentRoomKey: {
        type: Number,
        required: true
      }
    },
    data () {
      return {
        timer: [],
        btnLoading: [],
        btnWidth: 0
      }
    },
    computed: {
      tableFields () {
        return [
          { key: 'category', label: this.trans('global.category') },
          { key: 'name', label: this.trans('prospects.designer.form_manage_rooms.custom_name') },
          { key: 'order', label: this.trans('global.order'), class: 'ordercell' },
          { key: 'actions', label: this.trans('global.actions'), class: 'actioncell' }
        ]
      },
      tableData () {
        const data = []

        Object.keys(this.prospect.rooms).map((roomKey) => {
          if (
            this.prospect.rooms[roomKey].deleted === undefined ||
            this.prospect.rooms[roomKey].deleted !== true
          ) {
            data.push({
              roomkey: roomKey,
              category: this.trans('global.' + varyConfig.roomCategories[this.prospect.rooms[roomKey].category]),
              name: this.prospect.rooms[roomKey].customname !== undefined && this.prospect.rooms[roomKey].customname !== '' ? this.prospect.rooms[roomKey].customname : '',
              order: this.prospect.rooms[roomKey].order !== undefined ? this.prospect.rooms[roomKey].order : 0
            })
          }
        })

        return data
      },
      buttonIsLoading () {
        const buttons = {}

        Object.keys(this.prospect.rooms).map((roomKey) => {
          buttons[roomKey] = this.btnLoading.includes(roomKey)
        })

        return buttons
      }
    },
    mounted () {
      this.$root.$on('bv::modal::hide', () => { })
    },
    methods: {
      updateRoomOrder (roomKey, value) {
        const self = this

        if (roomKey !== undefined && value !== undefined) {
          this.inputTimer(roomKey).then(() => {
            const orderNum = isNaN(parseInt(value)) ? 0 : parseInt(value)
            fb.prospectsCollection.doc(this.prospect._id).update({
              [`rooms.${roomKey}.order`]: orderNum
            }).then(() => {
              this.$bvToast.toast(this.trans('prospects.designer.form_manage_rooms.updated', { room: self.trans('global.' + varyConfig.roomCategories[self.prospect.rooms[roomKey].category]) }), {
                title: this.trans('global.success'),
                variant: 'success',
                solid: true
              })
            }).catch((err) => {
              this.$bvToast.toast(this.trans('prospects.designer.form_manage_rooms.updated', { room: self.trans('global.' + varyConfig.roomCategories[self.prospect.rooms[roomKey].category]) }), {
                title: this.trans('global.error'),
                variant: 'warning',
                solid: true
              })
              console.log(err)
            })
          })
        }
      },
      inputTimer (roomKey) {
        return new Promise((resolve) => {
          if (this.timer[roomKey]) {
            clearTimeout(this.timer[roomKey])
            this.timer[roomKey] = null
          }

          this.timer[roomKey] = setTimeout(() => {
            resolve()
          }, 600)
        })
      },
      updateRoomName (room) {
        const self = this
        const roomName = room.name
        const roomKey = room.roomkey

        if (roomName !== undefined && roomName !== '' && roomKey !== undefined) {
          this.btnWidth = this.$refs['roomsavedata-' + room.roomkey].offsetWidth
          this.btnLoading.push(room.roomkey)

          fb.prospectsCollection.doc(this.prospect._id).update({
            [`rooms.${roomKey}.customname`]: roomName
          }).then(() => {
            self.$bvToast.toast(self.trans('prospects.designer.form_manage_rooms.update_success'), {
              title: self.trans('global.success'),
              variant: 'success',
              solid: true
            })

            this.btnLoading = this.btnLoading.filter((key) => {
              return key !== roomKey
            })
          }).catch((err) => {
            self.$bvToast.toast(self.trans('prospects.designer.form_manage_rooms.update_error'), {
              title: self.trans('global.error'),
              variant: 'warning',
              solid: true
            })

            console.log(err)

            this.btnLoading = this.btnLoading.filter((key) => {
              return key !== roomKey
            })
          })
        }
      },
      deleteRoom (roomData) {
        const self = this
        console.log('RoomData:', roomData)
        const roomName = roomData.name !== undefined && roomData.name !== '' ? roomData.name : roomData.category

        this.$bvModal.msgBoxConfirm(
          this.trans('prospects.designer.form_manage_rooms.delete_room', { roomname: roomName }),
          {
            title: this.trans('prospects.designer.form_manage_rooms.delete_room_title'),
            size: 'md',
            buttonSize: 'md',
            okVariant: 'danger',
            okTitle: this.trans('global.delete'),
            cancelTitle: this.trans('global.cancel')
          }
        ).then((value) => {
          if (value !== false) {
            const roomKey = roomData.roomkey

            fb.prospectsCollection.doc(this.prospect._id).update({
              [`rooms.${roomKey}.selected`]: 0,
              [`rooms.${roomKey}.deleted`]: true
            }).then(() => {
              self.$bvToast.toast(self.trans('prospects.designer.form_manage_rooms.update_success'), {
                title: self.trans('global.success'),
                variant: 'success',
                solid: true
              })
            }).catch((err) => {
              self.$bvToast.toast(self.trans('prospects.designer.form_manage_rooms.update_error'), {
                title: self.trans('global.error'),
                variant: 'warning',
                solid: true
              })

              console.log(err)
            })
          } else {
            console.log('Avbrutt...')
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #room-product-list {

    /deep/ thead {
      tr {
        th {
          // width: 50%;
          border: none;

          &.ordercell {
            width: 100px;
            overflow: hidden;
          }

          &.actioncell {
            text-align: right;
          }
        }
      }
    }

    /deep/ tbody {
      tr {
        td {
          vertical-align: middle;

          &.ordercell {
            width: 100px;
            overflow: hidden;

            form {
              input {
                width: 100%;
              }
            }
          }

          &.actioncell {
            text-align: right;
          }
        }
      }
    }
  }
</style>
