<template>
  <div>
    <b-form @submit.prevent="updateProduct">
      <b-modal
        :id="'edit-prospect-product-modal-' + productkey"
        size="lg"
        body-class="formified"
        footer-class="loadingfix"
        no-stacking
        scrollable
      >
        <template v-slot:modal-header>
          <h3>{{ trans('prospects.designer.form_edit_product.edit') }}</h3>
          <p class="description">
            {{ trans('prospects.designer.form_edit_product.edit_description') }}
          </p>
        </template>

        <template v-slot:default>
          <b-row>
            <b-col class="margin-bottom-26">
              <b-img v-if="productImage" :src="productImage" width="300"></b-img>
              <div v-else class="empty-image-container"></div>
            </b-col>
          </b-row>
          <b-row class="flex-column">
            <b-col>
              <b-form-group id="product_name_group" :label="trans('prospects.designer.form_add_room_new_product.name')" label-for="product_name">
                <b-form-input
                  id="product_name"
                  v-model="$v.productForm.name.$model"
                  name="product_name"
                  class="form-control-lg"
                  :state="formErrors.name"
                  type="text"
                  :placeholder="trans('prospects.designer.form_add_room_new_product.name_description')"
                  aria-describedby="name-msg"
                  trim
                ></b-form-input>
                <b-form-invalid-feedback id="name-msg">
                  <span v-if="!$v.productForm.name.minlength">{{ trans('prospects.designer.form_add_room_new_product.name_min_length') }}</span>
                  <span v-if="!$v.productForm.name.required">{{ trans('prospects.designer.form_add_room_new_product.name_required') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                id="product_shorttxt_group"
                label="Produktbeskrivelse"
                label-for="product_shorttxt"
                class="vary-wysiwyg"
              >
                <editor-menu-bubble
                  v-slot="{ commands, isActive, getMarkAttrs, menu }"
                  class="menububble"
                  :editor="editor"
                  @hide="hideLinkMenu"
                >
                  <div
                    class="menububble"
                    :class="{ 'is-active': menu.isActive }"
                    :style="`left: ${menu.left + 80}px; bottom: ${menu.bottom}px;`"
                  >
                    <form
                      v-if="linkMenuIsActive"
                      class="menububble__form"
                      @submit.prevent="setLinkUrl(commands.link, linkUrl)"
                    >
                      <input
                        ref="linkInput"
                        v-model="linkUrl"
                        class="menububble__input"
                        type="text"
                        placeholder="https://"
                        @keydown.esc="hideLinkMenu"
                      />
                      <button
                        class="menububble__button"
                        type="button"
                        @click="setLinkUrl(commands.link, null)"
                      >
                        <Icon icon="remove" />
                      </button>
                    </form>

                    <template v-else>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.link() }"
                        @click="showLinkMenu(getMarkAttrs('link'))"
                      >
                        <span>{{ isActive.link() ? 'Update Link' : 'Add Link' }}</span>
                        <Icon icon="link" />
                      </button>
                    </template>
                  </div>
                </editor-menu-bubble>

                <editor-menu-bar v-slot="{ commands, isActive }" class="menubar" :editor="editor">
                  <b-button-group>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                      @click="commands.heading({ level: 1 })"
                    >
                      H1
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                      @click="commands.heading({ level: 2 })"
                    >
                      H2
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                      @click="commands.heading({ level: 3 })"
                    >
                      H3
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bold() }"
                      @click="commands.bold"
                    >
                      <Icon icon="bold"></Icon>
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.italic() }"
                      @click="commands.italic"
                    >
                      <Icon icon="italic"></Icon>
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.strike() }"
                      @click="commands.strike"
                    >
                      <Icon icon="strike"></Icon>
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.underline() }"
                      @click="commands.underline"
                    >
                      <Icon icon="underline"></Icon>
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bullet_list() }"
                      @click="commands.bullet_list"
                    >
                      <Icon icon="ul"></Icon>
                    </button>
                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.ordered_list() }"
                      @click="commands.ordered_list"
                    >
                      <Icon icon="ol"></Icon>
                    </button>
                  </b-button-group>
                </editor-menu-bar>
                <editor-content class="editor" :editor="editor" />
              </b-form-group>
              <!--              <b-form-group id="product_shorttxt_groupp" :label="trans('prospects.designer.form_add_room_new_product.short_information')" label-for="product_shorttxt">-->
              <!--                <b-form-textarea-->
              <!--                  id="product_shorttxt"-->
              <!--                  v-model="productForm.shorttxt"-->
              <!--                  name="product_shorttxt"-->
              <!--                  class="form-control-lg"-->
              <!--                  :placeholder="trans('prospects.designer.form_add_room_new_product.short_information_description')"-->
              <!--                  rows="2"-->
              <!--                  max-rows="4"-->
              <!--                ></b-form-textarea>-->
              <!--              </b-form-group>-->
            </b-col>
            <b-col>
              <b-form-group id="product_longtxt_group" :label="trans('prospects.designer.form_add_room_new_product.long_information')" label-for="product_longtxt">
                <b-form-textarea
                  id="product_longtxt"
                  v-model="productForm.longtxt"
                  name="product_longtxt"
                  class="form-control-lg"
                  :placeholder="trans('prospects.designer.form_add_room_new_product.long_information_description')"
                  rows="6"
                  max-rows="12"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </template>

        <template v-slot:modal-footer="{ cancel }">
          <b-button variant="light" size="md" class="padding-left-60 padding-right-60" @click="cancel()">
            {{ trans('global.cancel') }}
          </b-button>
          <b-button
            v-if="submitStatus === 1"
            ref="submitbtn"
            variant="primary"
            size="md"
            class="padding-left-60 padding-right-60"
            disabled
          >
            <b-spinner variant="white"></b-spinner>
          </b-button>
          <b-button
            v-else
            ref="submitbtn"
            variant="primary"
            size="md"
            class="padding-left-60 padding-right-60"
            @click="updateProduct"
          >
            <span>{{ trans('global.save') }}</span>
          </b-button>
        </template>
      </b-modal>
    </b-form>
  </div>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { minLength, required } from 'vuelidate/lib/validators'

  import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
  import {
    Heading,
    Blockquote,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline
  } from 'tiptap-extensions'

  import Icon from '../../../../../Core/WysiwygIcons'

  export class CustomLink extends Link {
    get schema () {
      return {
        attrs: {
          href: {
            default: null
          },
          target: {
            default: null
          }
        },
        inclusive: false,
        parseDOM: [
          {
            tag: 'a[href]',
            getAttrs: dom => ({
              href: dom.getAttribute('href'),
              target: dom.getAttribute('target')
            })
          }
        ],
        toDOM: node => ['a', {
          ...node.attrs,
          target: '__blank',
          rel: 'noopener noreferrer nofollow'
        }, 0]
      }
    }
  }

  const fb = require('../../../../../../firebaseConfig')
  const varyConfig = require('../../../../../../varyConfig')

  export default {
    name: 'ModalEditProspectProduct',
    components: {
      EditorContent,
      EditorMenuBar,
      EditorMenuBubble,
      Icon
    },
    mixins: [validationMixin],
    props: {
      prospect: {
        type: Object,
        required: true
      },
      roomkey: {
        type: Number,
        required: true
      },
      productkey: {
        type: String,
        required: true
      }
    },
    data () {
      return {
        linkUrl: null,
        linkMenuIsActive: false,
        editor: null,
        productDescHtml: '',
        submitStatus: 0, // 0 = idle, 1 = processing
        progress: 0,
        roomCategories: varyConfig.roomCategories
      }
    },
    validations () {
      return {
        productForm: {
          name: {
            required,
            minlength: minLength(2)
          }
        }
      }
    },
    computed: {
      productData () {
        return this.prospect.rooms[this.roomkey].products[this.productkey]
      },
      productImage () {
        let imageUrl = false

        console.log(this.productData)
        if (this.productData.image !== undefined && Object.keys(this.productData.image).length > 0) {
          const imageData = this.productData.image[Object.keys(this.productData.image).pop()]

          imageUrl = process.env.VUE_APP_IMAGE_CDN_URL + '/' + imageData.path + '/' + imageData.file + '?fit=crop&w=300&mask=corners&corner-radius=5,5,5,5'
        }

        return imageUrl
      },
      productForm () {
        const data = {}

        data.name = this.productData !== undefined &&
          this.productData.name !== undefined
          ? this.productData.name
          : ''

        data.shorttxt = this.productData !== undefined &&
          this.productData.shorttxt !== undefined
          ? this.productData.shorttxt
          : ''

        data.longtxt = this.productData !== undefined &&
          this.productData.longtxt !== undefined
          ? this.productData.longtxt
          : ''

        return data
      },
      formErrors () {
        return {
          name: this.$v.productForm.name.$dirty && this.submitStatus === 1 ? !this.$v.productForm.name.$error : null
        }
      }
    },
    mounted () {
      this.initializeEditor()
    },
    beforeDestroy () {
      this.editor.destroy()
    },
    methods: {
      initializeEditor () {
        const self = this
        self.editor = new Editor({
          extensions: [
            new Heading({ levels: [1, 2, 3] }),
            new Blockquote(),
            new OrderedList(),
            new BulletList(),
            new ListItem(),
            new TodoItem(),
            new TodoList(),
            new Bold(),
            new Italic(),
            new CustomLink({ openOnClick: false }),
            new Strike(),
            new Underline()
          ],
          content: this.productData.shorttxt,
          onUpdate: ({ getHTML }) => {
            this.productDescHtml = getHTML()
          }
        })
      },
      updateProduct () {
        const self = this
        this.submitStatus = 1
        fb.prospectsCollection.doc(this.prospect._id).update({
          [`rooms.${self.roomkey}.products.${self.productkey}.name`]: self.productForm.name,
          [`rooms.${self.roomkey}.products.${self.productkey}.shorttxt`]: self.productDescHtml,
          [`rooms.${self.roomkey}.products.${self.productkey}.longtxt`]: self.productForm.longtxt
        }).then(() => {
          self.$bvToast.toast(self.trans('prospects.designer.form_add_room_new_product.product_added_successfully'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })

          this.submitStatus = 0
          self.$root.$emit('bv::hide::modal', 'edit-prospect-product-modal')
        }).catch((err) => {
          this.submitStatus = 0
          console.log('Error while saving the product data')
          console.log(err)
        })
      },
      showLinkMenu (attrs) {
        this.linkUrl = attrs.href
        this.linkMenuIsActive = true
        this.$nextTick(() => {
          this.$refs.linkInput.focus()
        })
      },
      hideLinkMenu () {
        this.linkUrl = null
        this.linkMenuIsActive = false
      },
      setLinkUrl (command, url) {
        command({ href: url })
        this.hideLinkMenu()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .empty-image-container {
    width: 300px;
    height: 300px;
    background: #cbcbcb;
  }

  .menububble {
    position: absolute;
    display: -webkit-box;
    display: flex;
    z-index: 20;
    background: #000;
    border-radius: 5px;
    padding: .3rem;
    margin-bottom: .5rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .2s,visibility .2s;
    transition: opacity .2s,visibility .2s
  }

  .menububble.is-active {
    opacity: 1;
    visibility: visible
  }

  .menububble__button {
    display: -webkit-inline-box;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #fff;
    padding: .2rem .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    cursor: pointer
  }

  .menububble__button:last-child {
    margin-right: 0
  }

  .menububble__button:hover {
    background-color: hsla(0,0%,100%,.1)
  }

  .menububble__button.is-active {
    background-color: hsla(0,0%,100%,.2)
  }

  .menububble__form {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center
  }
</style>
